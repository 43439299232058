import './style.css';

export default (props) => {
  
    return (

        <section id="splash">
        <p>
            Now that Algorand protocol is providing an incentive for running
            an Algorand node, you might find that your chances of 
           proposing a block are quite slim as whales tend to propose 
           the vast majority of them ...
        </p>
        <p>
           To provide everyone with the chance to participate in the 
           consensus process and receive rewards, we need to come 
           together and unite our efforts as a single large network of proposers.
           It's free, it's safe, your algos stay into your wallet and it helps
           the network to make the concensus more decentralized !
        </p>
           
        <button onClick={props.onClose}>Let's Go !</button>
        </section> 
    );
}

