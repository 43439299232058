// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
#add-stake{
   
    td{
        padding-bottom:10px;
    }
    .td-amount{
        
        padding-left:20px;
    }
}`, "",{"version":3,"sources":["webpack://./src/components/user-add-stake/style.css"],"names":[],"mappings":";AACA;;IAEI;QACI,mBAAmB;IACvB;IACA;;QAEI,iBAAiB;IACrB;AACJ","sourcesContent":["\r\n#add-stake{\r\n   \r\n    td{\r\n        padding-bottom:10px;\r\n    }\r\n    .td-amount{\r\n        \r\n        padding-left:20px;\r\n    }\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
