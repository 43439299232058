// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* HTML: <div class="loader"></div> */
.loader {
    width: 25px;
    aspect-ratio: 1;
    border-radius: 50%;
    border: 3px solid var(--main-green);
    animation: l20-1 0.8s infinite linear alternate, l20-2 1.6s infinite linear;
}
@keyframes l20-1{
   0%    {clip-path: polygon(50% 50%,0       0,  50%   0%,  50%    0%, 50%    0%, 50%    0%, 50%    0% )}
   12.5% {clip-path: polygon(50% 50%,0       0,  50%   0%,  100%   0%, 100%   0%, 100%   0%, 100%   0% )}
   25%   {clip-path: polygon(50% 50%,0       0,  50%   0%,  100%   0%, 100% 100%, 100% 100%, 100% 100% )}
   50%   {clip-path: polygon(50% 50%,0       0,  50%   0%,  100%   0%, 100% 100%, 50%  100%, 0%   100% )}
   62.5% {clip-path: polygon(50% 50%,100%    0, 100%   0%,  100%   0%, 100% 100%, 50%  100%, 0%   100% )}
   75%   {clip-path: polygon(50% 50%,100% 100%, 100% 100%,  100% 100%, 100% 100%, 50%  100%, 0%   100% )}
   100%  {clip-path: polygon(50% 50%,50%  100%,  50% 100%,   50% 100%,  50% 100%, 50%  100%, 0%   100% )}
}
@keyframes l20-2{ 
  0%    {transform:scaleY(1)  rotate(0deg)}
  49.99%{transform:scaleY(1)  rotate(135deg)}
  50%   {transform:scaleY(-1) rotate(0deg)}
  100%  {transform:scaleY(-1) rotate(-135deg)}
}`, "",{"version":3,"sources":["webpack://./src/elements/loader/style.css"],"names":[],"mappings":"AAAA,qCAAqC;AACrC;IACI,WAAW;IACX,eAAe;IACf,kBAAkB;IAClB,mCAAmC;IACnC,2EAA2E;AAC/E;AACA;GACG,OAAO,8FAA8F;GACrG,OAAO,8FAA8F;GACrG,OAAO,8FAA8F;GACrG,OAAO,8FAA8F;GACrG,OAAO,8FAA8F;GACrG,OAAO,8FAA8F;GACrG,OAAO,8FAA8F;AACxG;AACA;EACE,OAAO,iCAAiC;EACxC,OAAO,mCAAmC;EAC1C,OAAO,iCAAiC;EACxC,OAAO,oCAAoC;AAC7C","sourcesContent":["/* HTML: <div class=\"loader\"></div> */\n.loader {\n    width: 25px;\n    aspect-ratio: 1;\n    border-radius: 50%;\n    border: 3px solid var(--main-green);\n    animation: l20-1 0.8s infinite linear alternate, l20-2 1.6s infinite linear;\n}\n@keyframes l20-1{\n   0%    {clip-path: polygon(50% 50%,0       0,  50%   0%,  50%    0%, 50%    0%, 50%    0%, 50%    0% )}\n   12.5% {clip-path: polygon(50% 50%,0       0,  50%   0%,  100%   0%, 100%   0%, 100%   0%, 100%   0% )}\n   25%   {clip-path: polygon(50% 50%,0       0,  50%   0%,  100%   0%, 100% 100%, 100% 100%, 100% 100% )}\n   50%   {clip-path: polygon(50% 50%,0       0,  50%   0%,  100%   0%, 100% 100%, 50%  100%, 0%   100% )}\n   62.5% {clip-path: polygon(50% 50%,100%    0, 100%   0%,  100%   0%, 100% 100%, 50%  100%, 0%   100% )}\n   75%   {clip-path: polygon(50% 50%,100% 100%, 100% 100%,  100% 100%, 100% 100%, 50%  100%, 0%   100% )}\n   100%  {clip-path: polygon(50% 50%,50%  100%,  50% 100%,   50% 100%,  50% 100%, 50%  100%, 0%   100% )}\n}\n@keyframes l20-2{ \n  0%    {transform:scaleY(1)  rotate(0deg)}\n  49.99%{transform:scaleY(1)  rotate(135deg)}\n  50%   {transform:scaleY(-1) rotate(0deg)}\n  100%  {transform:scaleY(-1) rotate(-135deg)}\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
