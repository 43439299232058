import './style.css';
import Button   from '../../elements/button-fat/button-fat';
import Api      from '../../includes/api';
import User     from './user';
import Auth     from './auth';


import React, { useState, useEffect,useRef }    from 'react';

export default (props) => {
   
    const [is_logged_in,    setIsLoggedIn]    = useState(null);
        
    useEffect( () => {

        isLoggedIn();              
    });
    
    const isLoggedIn = async () => {
        
        const is_logged_in = await Api.get("user");
        
        if( !is_logged_in.error && is_logged_in.content){
            
            setIsLoggedIn(true);
        }
        else{
            
            setIsLoggedIn(false);
        }
    };
    
    const draw = (is_logged_in) => {
        
        let res = "";
        
        if(is_logged_in === true){
            
            res = <User />
        }
        else if(is_logged_in === false){
            
            res = <Auth />
        }
        
        return res;
    };
 
   
    return ( draw(is_logged_in) );
} 