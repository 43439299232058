import './style.css';
import Button   from '../../elements/button-fat/button-fat';
import Api      from '../../includes/api';
import { passwordStrength } from 'check-password-strength'

import React, { useState, useEffect,useRef }    from 'react';

export default (props) => {
   
    const [email_address,               setEmailAddress]            = useState(null);
    const [email_confirmation_sent,     setEmailConfirmationSent]   = useState(null);    
    const [reset_code,                  setResetCode]               = useState(null);    
    const [new_password,                setNewPassword]             = useState(null); 
    const [show_clear_password,         setShowClearPassword]       = useState(null);
    const [error_password,              setErrorPassword]           = useState(null);
    
    
    const onClickResetPassword = async () => {

        const send = await Api.post("user/reset-password/request", {email_address:email_address});
     
        if( !send.error && send.content ){
            
            setEmailConfirmationSent(true);
        }
    };


    const onClickSaveNewPassword = async () => {

        const send = await Api.post("user/reset-password/process", {email_address:email_address, code:reset_code, password:new_password});
     
        if( !send.error && send.content ){
            
            setTimeout(async () => {
                
                const auth = await Api.post("auth", {email_address:email_address, password:new_password});

                if( !auth.error && auth.content ){

                    localStorage.setItem("auth", auth.content);

                    window.location.pathname = "/profile";
                }
                
            }, 3000);
        }
    };
    
    const getPasswordStrengthClass = () => {
        
        const value = passwordStrength(new_password).value;        
        const colors = {
            
            "Too weak"  : "too-weak",
            "Weak"      : "weak",
            "Medium"    : "medium",
            "Strong"    : "strong"            
        };
        
        return colors[value] ?? "too-weak";
    };
    
    return (                 
        <div id="reset-password-content">
            <div class="white-container">
            { 
                email_confirmation_sent === null ?
                    <>
                    <label>
                        Enter your email address                
                        <input class="large-input" type="text" onChange={e => setEmailAddress(e.target.value)} />
                    </label>  

                    <Button value='Reset password' onClick={onClickResetPassword} />
                    </>
                :
                    <>
                        <div>
                        <label>
                            Enter the code received on your email address               
                            <input class="large-input" type="text" onChange={e => setResetCode(e.target.value)} />
                        </label>  
                      
                         <label>
                            Enter your new password
                            <div>{error_password ? <div class="error">{error_password}</div> : ""}</div>
                            <div id="password-panel">

                            <input 
                                class       = "large-input" 
                                type        = {show_clear_password ? "text" : "password"} 
                                id          = "create-password" 
                                onChange    = {e => setNewPassword(e.target.value)} />                
                            {
                                show_clear_password ? 
                                    <i class="fa fa-eye" onClick={e => setShowClearPassword(false)}></i> 
                                : 
                                    <i class="fa fa-eye-slash" onClick={e => setShowClearPassword(true)}></i> 
                            }
                            </div>
                            <div id="create-password-strength" class={getPasswordStrengthClass()}>{new_password ? passwordStrength(new_password).value : ""}</div>
                        </label> 
            
                        <Button value='Save the new password' onClick={onClickSaveNewPassword} />  
                        </div>
                    </>
            }
            </div>   
        </div>                    
    );
} 