import './style.css';
import React, { useState, useEffect,useRef }    from 'react';

export default (props) => {
   
    const [position, setPosition] = useState(props.state ?? "off");
    
    const onChangePosition = () => {
        
        const new_position = position === "off" ? "on" : "off";
        
        setPosition(new_position);
        
        props.onChange(new_position, props.id);
    };
    
    return (                 
        <div class="toggle" onClick={onChangePosition}>
            <div class={'selector ' + position} ></div>
        </div>
    );
} 