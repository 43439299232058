// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#modal{
    
    position: fixed;
    width: 100%;
    height: 100vh;
    background: rgba(0, 0, 0, 0.5);
    top: 0px;
    left: 0px;
    z-index:1;
    display:flex;
    
    #content{

        line-height: 26px;  
        max-width: 500px;
        background-color: white;
        padding: 15px;
        border-radius: 3px;       
        margin: auto;
               
        .small-btn{
            
            margin-top:20px;
        }
    }
}`, "",{"version":3,"sources":["webpack://./src/elements/modal/style.css"],"names":[],"mappings":"AAAA;;IAEI,eAAe;IACf,WAAW;IACX,aAAa;IACb,8BAA8B;IAC9B,QAAQ;IACR,SAAS;IACT,SAAS;IACT,YAAY;;IAEZ;;QAEI,iBAAiB;QACjB,gBAAgB;QAChB,uBAAuB;QACvB,aAAa;QACb,kBAAkB;QAClB,YAAY;;QAEZ;;YAEI,eAAe;QACnB;IACJ;AACJ","sourcesContent":["#modal{\r\n    \r\n    position: fixed;\r\n    width: 100%;\r\n    height: 100vh;\r\n    background: rgba(0, 0, 0, 0.5);\r\n    top: 0px;\r\n    left: 0px;\r\n    z-index:1;\r\n    display:flex;\r\n    \r\n    #content{\r\n\r\n        line-height: 26px;  \r\n        max-width: 500px;\r\n        background-color: white;\r\n        padding: 15px;\r\n        border-radius: 3px;       \r\n        margin: auto;\r\n               \r\n        .small-btn{\r\n            \r\n            margin-top:20px;\r\n        }\r\n    }\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
