import './style.css';
import Toggle       from '../../elements/toggle/toggle';
import ButtonFat    from '../../elements/button-fat/button-fat';
import Loader       from '../../elements/loader/loader';
import Api          from '../../includes/api';

import React, { useState, useEffect,useRef }    from 'react';

export default (props) => {
         
    const [is_logged_in,                setIsLoggedIn]          = useState(false);    
    const [is_admin,                    setIsAdmin]             = useState(false);     
    const [products,        setProducts]                        = useState([]);
    const [basket,          setBasket]                          = useState([]);
    const [basket_deploy,   setBasketDeploy]                    = useState(false);
    const [saved_quantity,  setSavedQuantity]                   = useState(null);
    
    useEffect( () => {

        listProducts();
        loadBasket();
        isLoggedIn();
        
    }, []);
    
    useEffect( () => {

        saveBasketToLocalstorage();
        
    }, [basket]);
       
    
    const isLoggedIn = async () => {
        
        const is_logged_in = await Api.get("user");
        
        if( !is_logged_in.error && is_logged_in.content){
            
            setIsLoggedIn(true);
            
            if(is_logged_in.content.is_admin){
                
                setIsAdmin(true);
            }
        }
        else{
         
            setIsLoggedIn(false);
        }
    };     

    const listProducts = async () => {
               
        const list = await Api.get("store");
       
        setProducts(list.content);
    };

    const loadBasket = () => {        

        const b64 = localStorage.getItem("store-basket") ?? null;
        
        if(b64){
            
            const base64_decoded_basket = JSON.parse(atob(b64));        
       
            setBasket(base64_decoded_basket);
        }
    };
    
    const drawBasket = (basket, deployed = false, products) => {
       
        const is_empty  = basket.length === 0;
        const res       = [];
        
        if(is_empty){
            
            res.push(<><i class="fa fa-shopping-cart"></i> Your basket is empty</>);
        }
        else{
            
            const build         = [];
            let price_total     = 0;
            let count_products  = 0;
            
            for(const product of basket){
                
                count_products  += product.quantity;
                price_total     += product.quantity * product.price;
                const picture   = products.filter(item => item.id === product.id)[0]?.picture ?? null;
                                
                build.push(
                    <tr>
                        <td class="title">
                            {picture ? <div class="white-container"><img src={picture} width="50" /></div> : <Loader />}
                            <div>{product.title}</div>
                        </td>
                        <td>x {product.quantity}</td>
                        <td>                                                       
                            <div class="quantity-changers">
                                <i class="fa fa-minus" aria-hidden="true"  onClick={(e) => removeOneFromBasket(product.id)}></i>
                                <i class="fa fa-plus" aria-hidden="true" onClick={(e) => addOneToBasket(product.id)}></i>
                            </div>
                        </td>
                        <td> {product.quantity * product.price}$</td>
                    </tr>
                )
            }
                                    
            if(deployed){
            
                res.push(
                    <>                
                        <i class="fa fa-shopping-cart"></i>
                        <span id="basket-summary">{count_products} article{count_products > 1 ? "s" : ""}</span>
                        <table id="deployed-basket">{build}</table> 
                        <div id="deployed-basket-total">Total: {price_total}$</div>  
                        <span id="basket-button-payment">
                            <ButtonFat value="Payment" onClick={proceedWithPayment} />
                        </span>
                        <ButtonFat value="Hide" onClick={(e)=>setBasketDeploy(false)} />
                    </>
                );
            }
            else{
            
                res.push(
                    <>
                        <i class="fa fa-shopping-cart"></i>
                        <span id="basket-summary">{count_products} article{count_products > 1 ? "s" : ""}: {price_total}$</span>
                        <span id="basket-button-payment">
                            <ButtonFat value="Payment" onClick={proceedWithPayment} />
                        </span>
                            <ButtonFat value="View" onClick={(e)=>setBasketDeploy(true)} />
                        
                    </>
                );
            }
            
            
        }
        
        return res;
    };
    
    const getAvailableQuantity = (product_id) => {
                
        const get_product = products.filter(product => product.id === product_id)[0];
        
        return get_product.quantity;
    };    
    
    const addOneToBasket = (id) => {
        
        let res         = [...basket];        
        const product   = products.filter(product => product.id === id)[0];
        const in_basket = basket.filter(product => product.id === id)[0];
        
        if( !in_basket ){                                    
            
            res.push({...product, quantity:1});
        }
        else{
                        
            res = [];
            
            for(const product of basket){
                              
                if(product.id === id){
                                        
                    const available_quantity    = getAvailableQuantity(product.id);
                    const basket_quantity       = product.quantity;
                    
                    if(available_quantity <= basket_quantity){
                        
                        console.log("max quantity was reached");
                    }
                    else{
                    
                        product.quantity++;    
                    }
                }
                
                res.push(product);
            } 
        }
                
        setBasket([...res]);
      
        return res;
    };
    
    const removeOneFromBasket = (id) => {
              
        const product   = products.filter(product => product.id === id)[0];
        const in_basket = basket.filter(product => product.id === id)[0];                               
        let res         = [];

        for(const product of basket){

            if(product.id === id){

                const available_quantity    = getAvailableQuantity(product.id);
                const basket_quantity       = product.quantity;

                if(basket_quantity === 1){

                    deleteFromBasket(product.id);
                    
                    return;
                }
                else{

                    product.quantity--;    
                }
            }

            res.push(product);
        }         
                
        setBasket([...res]);
    };
    
     const deleteFromBasket = (id) => {
                               
        let res = basket.filter(product => product.id !== id);

        setBasket([...res]);
    };
    
    const saveBasketToLocalstorage = () => {
    
        const order = [...basket];
        
        for(const product of basket){
            
            delete product.picture;
        }
        
        const base64_basket = btoa(JSON.stringify(order));
        
        localStorage.setItem("store-basket", base64_basket);
    };
    
    const changeAvailableQuantity = (id, quantity) => {
    
        for(const product of products){
                                        
            if(product.id === id){
                
                product.quantity = quantity;
            }
        }
        
        setProducts([...products]);        
    };
    
    const saveAvailableQuantity = async (id) => {
    
        const product   = products.filter(item => item.id === id)[0];
        const save      = await Api.post("store/product/update-quantity", {id:id, quantity:product.quantity});     
        
        setSavedQuantity(id);
        
        setTimeout(() => setSavedQuantity(null), 1000);
    };
    
    const deleteProduct = async (id) => {
    
        if(window.confirm("Are you sure you want to delete this product ?")){
            
            const remove = await Api.post("store/product/delete", {id:id});    
            
            listProducts();
        }
    };
    
    const proceedWithPayment = () => {
    
        window.location.href = "/store/payment";
    }
    
    return ( 
        <>
        
        <div id="store-container">    
            <div class="white-container" id="basket">
            {
                is_admin ? 
                <>
                <ButtonFat value="View orders" onClick={(e) => window.location.href = "/store/orders"} />
                <ButtonFat value="Add a new product" onClick={(e) => window.location.href = "/store/add"} />
                </>
                :
                drawBasket(basket, basket_deploy, products)
            }
            </div>
            { products.length === 0 ? <Loader /> : ""}
            {
                products.map(product => {
                    
                    return  <div class="white-container product">
                                <div class="picture"><img src={product.picture} width="200" height="250" /></div>
                                <div class="title">{product.title}</div>  
                                <div class="price">{product.price}$</div>
                                {
                                    is_admin ? 
                                    <div class="admin-panel">
                                        <p>Available quantity: </p>
                                        {
                                            saved_quantity === product.id ? 
                                            <Loader />
                                            :
                                            <div class="quantity-panel">                                           
                                                <input type="text" class="quantity" value={product.quantity} onChange={(e) => changeAvailableQuantity(product.id, e.target.value)}/>                                             
                                                <ButtonFat value="Save" onClick = {(e) => saveAvailableQuantity(product.id)} />                                          
                                            </div>
                                        }
                                        <div class="delete">
                                            <ButtonFat width="100%" value="Delete this product"  onClick={(e) => deleteProduct(product.id)}/>
                                        </div>
                                    </div> 
                                    : 
                                    <ButtonFat width="100%" value="Add to basket" onClick={(e)=>addOneToBasket(product.id)} />
                                }
                            </div>
                })
            }
        </div>
        </>
    );
} 