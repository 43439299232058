// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#footer{
      
    background:var(--main-green);
    text-align: center;
    color:white;
    
    #logo{
        
        padding:25px 0px;
        font-size: 1.5em;
        font-weight: bold;
    }
    
    #logo-text-1{
        
        color:var(--main-yellow);
    }
    
    #logo-text-2{
        
        color:white;
    }
    
    #label{
        
        padding:50px 0px
    }

    #socials{
        
        margin-bottom:15px;
    }
    
    #copyright{
        
        padding-bottom:25px;
    }
}
`, "",{"version":3,"sources":["webpack://./src/components/footer/style.css"],"names":[],"mappings":"AAAA;;IAEI,4BAA4B;IAC5B,kBAAkB;IAClB,WAAW;;IAEX;;QAEI,gBAAgB;QAChB,gBAAgB;QAChB,iBAAiB;IACrB;;IAEA;;QAEI,wBAAwB;IAC5B;;IAEA;;QAEI,WAAW;IACf;;IAEA;;QAEI;IACJ;;IAEA;;QAEI,kBAAkB;IACtB;;IAEA;;QAEI,mBAAmB;IACvB;AACJ","sourcesContent":["#footer{\r\n      \r\n    background:var(--main-green);\r\n    text-align: center;\r\n    color:white;\r\n    \r\n    #logo{\r\n        \r\n        padding:25px 0px;\r\n        font-size: 1.5em;\r\n        font-weight: bold;\r\n    }\r\n    \r\n    #logo-text-1{\r\n        \r\n        color:var(--main-yellow);\r\n    }\r\n    \r\n    #logo-text-2{\r\n        \r\n        color:white;\r\n    }\r\n    \r\n    #label{\r\n        \r\n        padding:50px 0px\r\n    }\r\n\r\n    #socials{\r\n        \r\n        margin-bottom:15px;\r\n    }\r\n    \r\n    #copyright{\r\n        \r\n        padding-bottom:25px;\r\n    }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
