import Socials          from "../socials/socials";
import NavigationLinks  from "../navigation-links/navigation-links";

import './style.css';

export default (props) => {
           
    return (                 
        <div id="footer">            
            <div id="logo">
                <span id="logo-text-1">Algorand</span>
                <span id="logo-text-2">Culture</span>            
            </div>
            <div id="socials">
                <Socials />
            </div>
            <span><i class="fas fa-envelope"></i> &nbsp; algorandculture@gmail.com</span>
            <div id="label">Boosting community engagement, onboarding normies and reducing friction between chains. By the culture #Fortheculture | #WeAreCMO</div>
            <div id="copyright">© 2024 Algorand Culture. All rights reserved.</div>
        </div>                       
    );
} 