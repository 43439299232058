import type { PlugableModules, Configs }    from 'algostack';
import AlgoStack                            from 'algostack';
import Client                               from 'algostack/client';
import Txns                                 from 'algostack/txns';

const globalConfigs: Configs = {
    apiUrl: 'https://testnet-api.algonode.cloud',
    indexerUrl: 'https://testnet-idx.algonode.cloud', 
  };

const modules: PlugableModules = { 
    
    client:   new Client({ namespace: 'wallet' }),
    txns:     new Txns(),
};

const algostack = new AlgoStack(globalConfigs, modules);

export default algostack;