import './style.css';
import Button   from '../../elements/button-fat/button-fat';
import Api      from '../../includes/api';
import UserNav  from '../user-navigation-links/user-navigation-links';

import React, { useState, useEffect,useRef }    from 'react';

export default (props) => {
   
    const [is_logged_in,    setIsLoggedIn]    = useState(null);
   
   
    useEffect( () => {

        
    });
    
   
   
    return (
           <div id="user-content">
            <UserNav />
            
            <div class="white-container">
            
            </div>   
        </div>                
    );
} 