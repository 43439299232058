import './style.css';
import PaymentPanel from '../payment-panel/payment-panel'
import ButtonFat    from '../../elements/button-fat/button-fat';
import Loader       from '../../elements/loader/loader';
import Password     from '../../elements/password/password';
import Api          from '../../includes/api';
import React, { useState, useEffect }    from 'react';

export default (props) => {
     
    const [is_logged_in,        setIsLoggedIn]          = useState(false);  
    const [logged_in_email,     setLoggedInEmail]       = useState(null);
    const [is_loading_basket,   setIsLoadingBasket]     = useState(true);
    const [basket,              setBasket]              = useState([]);
    const [pictures,            setPictures]            = useState({});
    const [total_price,         setTotalPrice]          = useState(null);
    const [address,             setAddress]             = useState({});
    const [errors,              setErrors]              = useState({});
    const [payment_data,        setPaymentData]         = useState(null);
    const [show_payment_panel,  setShowPaymentPanel]    = useState(false);
    
    const [not_logged_in_email,         setNotLoggedInEmail]        = useState(null); 
    const [not_logged_in_email_exists,  setNotLoggedInEmailExists]  = useState(null)
    const [password,                    setPassword]                = useState({password:null, strength:null, description:null});
    
    useEffect( () => {

        loadBasketFromLocalstorage();
        loadPrice();
        isLoggedIn();
        
        
    }, []);        
    
    const isLoggedIn = async () => {
        
        const is_logged_in = await Api.get("user");
        
        if( !is_logged_in.error && is_logged_in.content){
            
            setIsLoggedIn(true);
            setLoggedInEmail(is_logged_in.content.email);
            
            const get_address = await Api.get("user/postal-address");
            
            if(get_address.content !== null){
                
                setAddress(get_address.content);
            }
        }
        else{
         
            setIsLoggedIn(false);
        }
    }; 
    
    const loadBasketFromLocalstorage = async () => {        

        const b64 = localStorage.getItem("store-basket") ?? null;
        
        if(b64){
            
            const decoded_basket    = JSON.parse(atob(b64));
            const valid_basket      = [];
            let pictures            = {};
            
            for(const product of decoded_basket){
                
                const get_product = await Api.get("store/product", {id:product.id});
                
                if( !get_product.error ){
                
                    pictures[product.id] = get_product.content.picture;
                    
                    // Rebuild the basket with valid entries so if a product
                    // was deleted from the DB since the user added it to his basket,
                    // the code won't try to find it.
                    valid_basket.push(product); 
                }
            }
            
            setBasket(valid_basket);
            setPictures(pictures);
            setIsLoadingBasket(false);
        }
    };
    
    const loadPrice = () => {
           
        let price = 0;
        
        for(const product of basket) {

            price += product.price * product.quantity;
        }
        
        setTotalPrice(price);
    };
    
    const drawBasket = (basket, pictures) => {
    
        const res   = [];
        let total   =  0;
                
        for(const product of basket) {
                                   
            let price   = product.price * product.quantity;
            total       += price;
            
            res.push( 
                <tr>
                    <td>
                        <div class="product-picture-title">
                        {pictures[product.id] ? <div class="white-container picture"><img src={pictures[product.id]} width="50"/></div> : ""}
                        <div class="title">{product.title}</div>
                        </div>
                    </td>                                
                    <td>x{product.quantity}</td>
                    <td>{price}$</td>                                                   
                </tr>
            );
        }
        
        return is_loading_basket ? 
                <Loader />
                :
                <table>
                <tr>
                    <th>Product</th>
                    <th>Quantity</th>
                    <th>Price</th>
                </tr>
                {res}
                <tr>
                    <td colspan="3" id="total">Total: <b>{total}$</b></td>                    
                </tr>
                </table>                
    };
    
    const onChangeAddress = (property, value) => {        
        
        address[property] = value;
        
        setAddress({...address});
    };
    
    const onBlurEmailAddress = async (e) => {
        
        setNotLoggedInEmail(e.target.value);
        
        const is_user_registered = await Api.get("user/email-is-registered", {email:e.target.value});        
        
        if( !is_user_registered.error ){
                        
            setNotLoggedInEmailExists(is_user_registered.content);            
        }
        else{
            
            setNotLoggedInEmailExists(null);  
        }
    };
    
    const onChangePassword = (password, strength, description) => {
        
        setPassword({password:password, strength:strength, description:description});      
    };
    
    const onClickProceedPayment = async () => {
        
        setErrors({});
        
        const errors = {};
        const is_empty_entry = (property) => !address[property] || address[property].trim().length === 0;
       
        if( is_empty_entry("firstname") ){
            
            errors.firstname = "The firstname is missing";
        }
        
        if( is_empty_entry("lastname") ){
            
            errors.lastname = "The lastname is missing";
        }
        
        if( is_empty_entry("street") ){
            
            errors.street = "The street address is missing";
        }
        
        if( is_empty_entry("city") ){
            
            errors.city = "The city is missing";
        }
        
        if( is_empty_entry("zipcode") ){
            
            errors.zipcode = "The zipcode is missing";
        }
               
        if( !is_logged_in && !not_logged_in_email ){
            
            errors.email = "The email address is missing";
        }
        
        if( !is_logged_in && !password ){
            
            errors.password = "The password is missing";
        }
        
        setErrors({...errors});
        
        if(Object.keys(errors).length === 0){
            
            const order = await Api.post("store/order", {basket:basket, address:address, email:not_logged_in_email, password:password.password});
           
            if(order.error === true){
                
                if(order.content === "invalid_email_address"){
                    
                    errors.email = "Invalid email address";                                        
                }
                else if(order.content === "password_too_weak"){
                    
                    errors.password = "The password is too weak";
                }
                else if(order.content === "authentication_missing"){
                    
                    errors.password = "Wrong password";
                }
                else{
                    
                    errors.unexpected = "Unexpected error: "+order.content;
                }
                
                setErrors({...errors});
            }
            else{

                //setEventSuccessfullyPublished(true);
                setShowPaymentPanel(true);
                setPaymentData(order.content);
            }
                
        }
    };
    
    const drawError = (error) => {
        
        return <div class="error">{error}</div>
    }
    
    return ( 
        <>
        
        <div id="store-payment-container" class="white-container">    
            <div id="basket">
                {drawBasket(basket, pictures)}
            </div>
                <p>
                <label>
                    Firstname 
                    {errors?.firstname ? drawError(errors.firstname) : ""}
                    <input disabled = {show_payment_panel} type="text" value={address.firstname} onChange={e => onChangeAddress("firstname", e.target.value)}/>
                </label>
                </p>
                <p>
                <label>
                    Lastname 
                    {errors?.lastname ? drawError(errors.lastname) : ""}
                    <input disabled = {show_payment_panel} type="text" value={address.lastname}  onChange={e => onChangeAddress("lastname", e.target.value)}/>
                </label>
                </p>
                <p>
                <label>
                    Street address 
                    {errors?.street ? drawError(errors.street) : ""}
                    <textarea disabled = {show_payment_panel} value={address.street}  id="textarea-street" onChange={e => onChangeAddress("street", e.target.value)}/>
                </label>
                </p>
                <p>
                <label>
                    City
                    {errors?.city ? drawError(errors.city) : ""}
                    <input disabled = {show_payment_panel} type="text" value={address.city}  onChange={e => onChangeAddress("city", e.target.value)}/>
                </label>
                </p>
                <p>
                <label>
                    ZipCode
                    {errors?.zipcode ? drawError(errors.zipcode) : ""}
                    <input disabled = {show_payment_panel} type="text" value={address.zipcode}  onChange={e => onChangeAddress("zipcode", e.target.value)}/>
                </label>
                </p>
                <p>
                <label>
                Country
                <select  
                    disabled    = {show_payment_panel}
                    value       = {address.country} 
                    id          = "country" 
                    name        = "country" 
                    class       = "form-control" 
                    onChange    = {e => onChangeAddress("country", e.target.value)}>
                    <option value="Afghanistan">Afghanistan</option>
                    <option value="Åland Islands">Åland Islands</option>
                    <option value="Albania">Albania</option>
                    <option value="Algeria">Algeria</option>
                    <option value="American Samoa">American Samoa</option>
                    <option value="Andorra">Andorra</option>
                    <option value="Angola">Angola</option>
                    <option value="Anguilla">Anguilla</option>
                    <option value="Antarctica">Antarctica</option>
                    <option value="Antigua and Barbuda">Antigua and Barbuda</option>
                    <option value="Argentina">Argentina</option>
                    <option value="Armenia">Armenia</option>
                    <option value="Aruba">Aruba</option>
                    <option value="Australia">Australia</option>
                    <option value="Austria">Austria</option>
                    <option value="Azerbaijan">Azerbaijan</option>
                    <option value="Bahamas">Bahamas</option>
                    <option value="Bahrain">Bahrain</option>
                    <option value="Bangladesh">Bangladesh</option>
                    <option value="Barbados">Barbados</option>
                    <option value="Belarus">Belarus</option>
                    <option value="Belgium">Belgium</option>
                    <option value="Belize">Belize</option>
                    <option value="Benin">Benin</option>
                    <option value="Bermuda">Bermuda</option>
                    <option value="Bhutan">Bhutan</option>
                    <option value="Bolivia">Bolivia</option>
                    <option value="Bosnia and Herzegovina">Bosnia and Herzegovina</option>
                    <option value="Botswana">Botswana</option>
                    <option value="Bouvet Island">Bouvet Island</option>
                    <option value="Brazil">Brazil</option>
                    <option value="British Indian Ocean Territory">British Indian Ocean Territory</option>
                    <option value="Brunei Darussalam">Brunei Darussalam</option>
                    <option value="Bulgaria">Bulgaria</option>
                    <option value="Burkina Faso">Burkina Faso</option>
                    <option value="Burundi">Burundi</option>
                    <option value="Cambodia">Cambodia</option>
                    <option value="Cameroon">Cameroon</option>
                    <option value="Canada">Canada</option>
                    <option value="Cape Verde">Cape Verde</option>
                    <option value="Cayman Islands">Cayman Islands</option>
                    <option value="Central African Republic">Central African Republic</option>
                    <option value="Chad">Chad</option>
                    <option value="Chile">Chile</option>
                    <option value="China">China</option>
                    <option value="Christmas Island">Christmas Island</option>
                    <option value="Cocos (Keeling) Islands">Cocos (Keeling) Islands</option>
                    <option value="Colombia">Colombia</option>
                    <option value="Comoros">Comoros</option>
                    <option value="Congo">Congo</option>
                    <option value="Congo, The Democratic Republic of The">Congo, The Democratic Republic of The</option>
                    <option value="Cook Islands">Cook Islands</option>
                    <option value="Costa Rica">Costa Rica</option>
                    <option value="Cote D'ivoire">Cote D'ivoire</option>
                    <option value="Croatia">Croatia</option>
                    <option value="Cuba">Cuba</option>
                    <option value="Cyprus">Cyprus</option>
                    <option value="Czech Republic">Czech Republic</option>
                    <option value="Denmark">Denmark</option>
                    <option value="Djibouti">Djibouti</option>
                    <option value="Dominica">Dominica</option>
                    <option value="Dominican Republic">Dominican Republic</option>
                    <option value="Ecuador">Ecuador</option>
                    <option value="Egypt">Egypt</option>
                    <option value="El Salvador">El Salvador</option>
                    <option value="Equatorial Guinea">Equatorial Guinea</option>
                    <option value="Eritrea">Eritrea</option>
                    <option value="Estonia">Estonia</option>
                    <option value="Ethiopia">Ethiopia</option>
                    <option value="Falkland Islands (Malvinas)">Falkland Islands (Malvinas)</option>
                    <option value="Faroe Islands">Faroe Islands</option>
                    <option value="Fiji">Fiji</option>
                    <option value="Finland">Finland</option>
                    <option value="France">France</option>
                    <option value="French Guiana">French Guiana</option>
                    <option value="French Polynesia">French Polynesia</option>
                    <option value="French Southern Territories">French Southern Territories</option>
                    <option value="Gabon">Gabon</option>
                    <option value="Gambia">Gambia</option>
                    <option value="Georgia">Georgia</option>
                    <option value="Germany">Germany</option>
                    <option value="Ghana">Ghana</option>
                    <option value="Gibraltar">Gibraltar</option>
                    <option value="Greece">Greece</option>
                    <option value="Greenland">Greenland</option>
                    <option value="Grenada">Grenada</option>
                    <option value="Guadeloupe">Guadeloupe</option>
                    <option value="Guam">Guam</option>
                    <option value="Guatemala">Guatemala</option>
                    <option value="Guernsey">Guernsey</option>
                    <option value="Guinea">Guinea</option>
                    <option value="Guinea-bissau">Guinea-bissau</option>
                    <option value="Guyana">Guyana</option>
                    <option value="Haiti">Haiti</option>
                    <option value="Heard Island and Mcdonald Islands">Heard Island and Mcdonald Islands</option>
                    <option value="Holy See (Vatican City State)">Holy See (Vatican City State)</option>
                    <option value="Honduras">Honduras</option>
                    <option value="Hong Kong">Hong Kong</option>
                    <option value="Hungary">Hungary</option>
                    <option value="Iceland">Iceland</option>
                    <option value="India">India</option>
                    <option value="Indonesia">Indonesia</option>
                    <option value="Iran, Islamic Republic of">Iran, Islamic Republic of</option>
                    <option value="Iraq">Iraq</option>
                    <option value="Ireland">Ireland</option>
                    <option value="Isle of Man">Isle of Man</option>
                    <option value="Israel">Israel</option>
                    <option value="Italy">Italy</option>
                    <option value="Jamaica">Jamaica</option>
                    <option value="Japan">Japan</option>
                    <option value="Jersey">Jersey</option>
                    <option value="Jordan">Jordan</option>
                    <option value="Kazakhstan">Kazakhstan</option>
                    <option value="Kenya">Kenya</option>
                    <option value="Kiribati">Kiribati</option>
                    <option value="Korea, Democratic People's Republic of">Korea, Democratic People's Republic of</option>
                    <option value="Korea, Republic of">Korea, Republic of</option>
                    <option value="Kuwait">Kuwait</option>
                    <option value="Kyrgyzstan">Kyrgyzstan</option>
                    <option value="Lao People's Democratic Republic">Lao People's Democratic Republic</option>
                    <option value="Latvia">Latvia</option>
                    <option value="Lebanon">Lebanon</option>
                    <option value="Lesotho">Lesotho</option>
                    <option value="Liberia">Liberia</option>
                    <option value="Libyan Arab Jamahiriya">Libyan Arab Jamahiriya</option>
                    <option value="Liechtenstein">Liechtenstein</option>
                    <option value="Lithuania">Lithuania</option>
                    <option value="Luxembourg">Luxembourg</option>
                    <option value="Macao">Macao</option>
                    <option value="Macedonia, The Former Yugoslav Republic of">Macedonia, The Former Yugoslav Republic of</option>
                    <option value="Madagascar">Madagascar</option>
                    <option value="Malawi">Malawi</option>
                    <option value="Malaysia">Malaysia</option>
                    <option value="Maldives">Maldives</option>
                    <option value="Mali">Mali</option>
                    <option value="Malta">Malta</option>
                    <option value="Marshall Islands">Marshall Islands</option>
                    <option value="Martinique">Martinique</option>
                    <option value="Mauritania">Mauritania</option>
                    <option value="Mauritius">Mauritius</option>
                    <option value="Mayotte">Mayotte</option>
                    <option value="Mexico">Mexico</option>
                    <option value="Micronesia, Federated States of">Micronesia, Federated States of</option>
                    <option value="Moldova, Republic of">Moldova, Republic of</option>
                    <option value="Monaco">Monaco</option>
                    <option value="Mongolia">Mongolia</option>
                    <option value="Montenegro">Montenegro</option>
                    <option value="Montserrat">Montserrat</option>
                    <option value="Morocco">Morocco</option>
                    <option value="Mozambique">Mozambique</option>
                    <option value="Myanmar">Myanmar</option>
                    <option value="Namibia">Namibia</option>
                    <option value="Nauru">Nauru</option>
                    <option value="Nepal">Nepal</option>
                    <option value="Netherlands">Netherlands</option>
                    <option value="Netherlands Antilles">Netherlands Antilles</option>
                    <option value="New Caledonia">New Caledonia</option>
                    <option value="New Zealand">New Zealand</option>
                    <option value="Nicaragua">Nicaragua</option>
                    <option value="Niger">Niger</option>
                    <option value="Nigeria">Nigeria</option>
                    <option value="Niue">Niue</option>
                    <option value="Norfolk Island">Norfolk Island</option>
                    <option value="Northern Mariana Islands">Northern Mariana Islands</option>
                    <option value="Norway">Norway</option>
                    <option value="Oman">Oman</option>
                    <option value="Pakistan">Pakistan</option>
                    <option value="Palau">Palau</option>
                    <option value="Palestinian Territory, Occupied">Palestinian Territory, Occupied</option>
                    <option value="Panama">Panama</option>
                    <option value="Papua New Guinea">Papua New Guinea</option>
                    <option value="Paraguay">Paraguay</option>
                    <option value="Peru">Peru</option>
                    <option value="Philippines">Philippines</option>
                    <option value="Pitcairn">Pitcairn</option>
                    <option value="Poland">Poland</option>
                    <option value="Portugal">Portugal</option>
                    <option value="Puerto Rico">Puerto Rico</option>
                    <option value="Qatar">Qatar</option>
                    <option value="Reunion">Reunion</option>
                    <option value="Romania">Romania</option>
                    <option value="Russian Federation">Russian Federation</option>
                    <option value="Rwanda">Rwanda</option>
                    <option value="Saint Helena">Saint Helena</option>
                    <option value="Saint Kitts and Nevis">Saint Kitts and Nevis</option>
                    <option value="Saint Lucia">Saint Lucia</option>
                    <option value="Saint Pierre and Miquelon">Saint Pierre and Miquelon</option>
                    <option value="Saint Vincent and The Grenadines">Saint Vincent and The Grenadines</option>
                    <option value="Samoa">Samoa</option>
                    <option value="San Marino">San Marino</option>
                    <option value="Sao Tome and Principe">Sao Tome and Principe</option>
                    <option value="Saudi Arabia">Saudi Arabia</option>
                    <option value="Senegal">Senegal</option>
                    <option value="Serbia">Serbia</option>
                    <option value="Seychelles">Seychelles</option>
                    <option value="Sierra Leone">Sierra Leone</option>
                    <option value="Singapore">Singapore</option>
                    <option value="Slovakia">Slovakia</option>
                    <option value="Slovenia">Slovenia</option>
                    <option value="Solomon Islands">Solomon Islands</option>
                    <option value="Somalia">Somalia</option>
                    <option value="South Africa">South Africa</option>
                    <option value="South Georgia and The South Sandwich Islands">South Georgia and The South Sandwich Islands</option>
                    <option value="Spain">Spain</option>
                    <option value="Sri Lanka">Sri Lanka</option>
                    <option value="Sudan">Sudan</option>
                    <option value="Suriname">Suriname</option>
                    <option value="Svalbard and Jan Mayen">Svalbard and Jan Mayen</option>
                    <option value="Swaziland">Swaziland</option>
                    <option value="Sweden">Sweden</option>
                    <option value="Switzerland">Switzerland</option>
                    <option value="Syrian Arab Republic">Syrian Arab Republic</option>
                    <option value="Taiwan">Taiwan</option>
                    <option value="Tajikistan">Tajikistan</option>
                    <option value="Tanzania, United Republic of">Tanzania, United Republic of</option>
                    <option value="Thailand">Thailand</option>
                    <option value="Timor-leste">Timor-leste</option>
                    <option value="Togo">Togo</option>
                    <option value="Tokelau">Tokelau</option>
                    <option value="Tonga">Tonga</option>
                    <option value="Trinidad and Tobago">Trinidad and Tobago</option>
                    <option value="Tunisia">Tunisia</option>
                    <option value="Turkey">Turkey</option>
                    <option value="Turkmenistan">Turkmenistan</option>
                    <option value="Turks and Caicos Islands">Turks and Caicos Islands</option>
                    <option value="Tuvalu">Tuvalu</option>
                    <option value="Uganda">Uganda</option>
                    <option value="Ukraine">Ukraine</option>
                    <option value="United Arab Emirates">United Arab Emirates</option>
                    <option value="United Kingdom">United Kingdom</option>
                    <option value="United States">United States</option>
                    <option value="United States Minor Outlying Islands">United States Minor Outlying Islands</option>
                    <option value="Uruguay">Uruguay</option>
                    <option value="Uzbekistan">Uzbekistan</option>
                    <option value="Vanuatu">Vanuatu</option>
                    <option value="Venezuela">Venezuela</option>
                    <option value="Viet Nam">Viet Nam</option>
                    <option value="Virgin Islands, British">Virgin Islands, British</option>
                    <option value="Virgin Islands, U.S.">Virgin Islands, U.S.</option>
                    <option value="Wallis and Futuna">Wallis and Futuna</option>
                    <option value="Western Sahara">Western Sahara</option>
                    <option value="Yemen">Yemen</option>
                    <option value="Zambia">Zambia</option>
                    <option value="Zimbabwe">Zimbabwe</option>
                </select>
                </label>
                </p>
                {
                    !logged_in_email ?
                    <>
                    <p>
                    <label>
                        Email address
                        {errors?.email ? drawError(errors.email) : ""}
                        <input type="text" onBlur={onBlurEmailAddress} />                    
                    </label>
                    </p>
                    <p>
                    <label>
                        {not_logged_in_email_exists === null ? <span>Password</span>  : ""}
                        {not_logged_in_email_exists === true ? <span>Enter your password</span>  : ""}
                        {not_logged_in_email_exists === false ? <span>An account will be created for this email address.<br/>Enter a strong password</span>  : ""}
                        {errors?.password ? drawError(errors.password) : ""}                        
                       
                        <Password password={password.password} onChange={onChangePassword}/>
                    </label>
                    </p>
                    </>
                    : ""
                }
                {show_payment_panel ? <PaymentPanel payment_data={payment_data} /> : <p id="select-payment-method"><ButtonFat value="Select a payment method" onClick={onClickProceedPayment} /></p>}
    
        </div>
        </>
    );
} 