// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.must-login{
    
    max-width: 400px;
    min-width: 200px;
    margin: auto;
    text-align: center;
    
    p{
        margin-top:25px;
    }
        
}

`, "",{"version":3,"sources":["webpack://./src/elements/must-login/style.css"],"names":[],"mappings":"AAAA;;IAEI,gBAAgB;IAChB,gBAAgB;IAChB,YAAY;IACZ,kBAAkB;;IAElB;QACI,eAAe;IACnB;;AAEJ","sourcesContent":[".must-login{\n    \n    max-width: 400px;\n    min-width: 200px;\n    margin: auto;\n    text-align: center;\n    \n    p{\n        margin-top:25px;\n    }\n        \n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
