// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.loader,
.loader:after {
  border-radius: 50%;
  width: 3em;
  height: 3em;
}
.loader {
  
    font-size: 10px;
    position: relative;    
    border-top: 1em solid rgb(247 106 155);
    border-right: 1em solid rgb(45 174 214);
    border-bottom: 1em solid rgb(152 79 104);
    border-left: 1em solid #ffe108;
    transform: translateZ(0);
    animation: load8 0.3s infinite linear;
}
@keyframes load8 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}`, "",{"version":3,"sources":["webpack://./src/elements/loaders/style.css"],"names":[],"mappings":"AAAA;;EAEE,kBAAkB;EAClB,UAAU;EACV,WAAW;AACb;AACA;;IAEI,eAAe;IACf,kBAAkB;IAClB,sCAAsC;IACtC,uCAAuC;IACvC,wCAAwC;IACxC,8BAA8B;IAC9B,wBAAwB;IACxB,qCAAqC;AACzC;AAWA;EACE;IAEE,uBAAuB;EACzB;EACA;IAEE,yBAAyB;EAC3B;AACF","sourcesContent":[".loader,\n.loader:after {\n  border-radius: 50%;\n  width: 3em;\n  height: 3em;\n}\n.loader {\n  \n    font-size: 10px;\n    position: relative;    \n    border-top: 1em solid rgb(247 106 155);\n    border-right: 1em solid rgb(45 174 214);\n    border-bottom: 1em solid rgb(152 79 104);\n    border-left: 1em solid #ffe108;\n    transform: translateZ(0);\n    animation: load8 0.3s infinite linear;\n}\n@-webkit-keyframes load8 {\n  0% {\n    -webkit-transform: rotate(0deg);\n    transform: rotate(0deg);\n  }\n  100% {\n    -webkit-transform: rotate(360deg);\n    transform: rotate(360deg);\n  }\n}\n@keyframes load8 {\n  0% {\n    -webkit-transform: rotate(0deg);\n    transform: rotate(0deg);\n  }\n  100% {\n    -webkit-transform: rotate(360deg);\n    transform: rotate(360deg);\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
