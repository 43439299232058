import './style.css';
import FadeIn from 'react-fade-in';

export default (props) => {
  
    return (
                
        <div id="modal" onClick={props.onClose}>
            
            <div id="content">
                <FadeIn>
                <div class='title'>{props.title}</div>        
                <p>{props.content}</p>
                <div><button class="small-btn" onClick={props.onClose}>Close</button></div>
                </FadeIn>
            </div>
            
        </div> 
    );
}

