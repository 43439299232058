import './style.css';
import Api from '../../includes/api';

import React, { useState, useEffect,useRef }    from 'react';

export default (props) => {
   
    const [show_floating_menu,  setShowFloatingMenu]    = useState(false);
    const [is_mouse_in,         setIsMouseIn]           = useState(false);     
    const [pathname,            setPathname]            = useState(null);
    const [is_logged_in,        setIsLoggedIn]          = useState(null);
    
    useEffect(() => {
        
        setPathname(window.location.pathname);
        
        isLoggedIn(); 
        
    }, []);
    
    useEffect(() => {
        
        document.addEventListener('click', handleDocumentClick);

        return () => {
            
          document.removeEventListener('click', handleDocumentClick);
        };        
    });
    
    const isLoggedIn = async () => {
        
        const is_logged_in = await Api.get("user");
        
        if( !is_logged_in.error && is_logged_in.content){
            
            setIsLoggedIn(true);
        }
        else{
         
            setIsLoggedIn(false);
        }
    };    
    
    const onClickLogin = () => {
        
        if(show_floating_menu){
            
            setShowFloatingMenu(false);
        }
        else{
            
            setShowFloatingMenu(true);
        }
    };
    
    const onClickLogout = async () => {
        
        localStorage.removeItem("auth");
        
        window.location.pathname = "/";
    };

    const handleDocumentClick = () => {
        
        if( !is_mouse_in ){
            
            setShowFloatingMenu(false);
        }
    };
    
    const getPathname = (pathname) => {
        
        let res     = null;
        const pages = {
            
            home:       [""],
            events:     ["events", "events/schedule"],
            poker:      ["poker"],
            store:      ["store"],
            memes:      ["memes"],
            blog:       ["blog"],
            services:   ["services"],
            profile:    ["create-account", "profile", "profile/events"]
        };
        
        const root_path = window.location.pathname.split("/")[1];
        
        for(const page in pages){
            
            if(pages[page].includes(root_path)){
            
                res = page;
            
                break;
            }
        }
        
        return res;
    };
    
    const drawLoginButton = (is_logged_in) => {
        
        let res = null;

        // 'is_logged_in is null' only when the endpoint /user is loading then
        // its value goes true or false. 
         if(localStorage.getItem("auth")){
        
            res = <a 
                onClick         = {onClickLogin} 
                onMouseEnter    = {(e) => setIsMouseIn(true)}
                onMouseLeave    = {(e) => setIsMouseIn(false)}
                href            = "#" 
                id              = "login"                     
                class           = {show_floating_menu || getPathname(pathname) === "profile" ? "selected" : ""}>
                <i class="fa fa-user"></i>                             
            </a>
        }
        else{

            res = <a                               
                href            = "/profile" 
                id              = "login"                     
                class           = {getPathname(pathname) === "profile" ? "selected" : ""}>
                <i class="fa fa-user"></i> 
                <div id="label">Login</div>
            </a>
        }
        
        return res;
    };
        

    return (                 
        <ul id="navigation-links">
            <li><a href="/"             class={getPathname(pathname) === "home" ? "selected" : ""}><div>Home</div></a></li>
            <li><a href="/events"       class={getPathname(pathname) === "events" ? "selected" : ""}><div>Events</div></a></li>
            <li><a href="/poker"        class={getPathname(pathname) === "poker" ? "selected" : ""}><div>Poker</div></a></li>
            <li><a href="/store"        class={getPathname(pathname) === "store" ? "selected" : ""}><div>Store</div></a></li>
            <li><a href="/memes"        class={getPathname(pathname) === "memes" ? "selected" : ""}><div>Memes</div></a></li>
            <li><a href="/community"    target="_blank" href="https://app.nf.domains/name/fortheculture.algo?view=segments"><div>Community</div></a></li>
            <li><a href="/blog"         class={getPathname(pathname) === "blog" ? "selected" : ""}><div>Blog</div></a></li>
            <li><a href="/services"     class={getPathname(pathname) === "services" ? "selected" : ""}><div>Services</div></a></li>
            <li>
                {
                    drawLoginButton(is_logged_in)
                }
                
                {
                    show_floating_menu ? 
                        <div 
                            id              ="floating-menu" 
                            onMouseEnter    = {(e) => setIsMouseIn(true)}
                            onMouseLeave    = {(e) => setIsMouseIn(false)}>
                            
                            <ul>
                                <li><a href="/profile">Dashboard</a></li>
                                <li><a href="#" onClick={onClickLogout}>Logout</a></li>
                            </ul>
                        </div> 
                    : 
                        ""
                }
            </li>
            
        </ul>
                      
    );
} 