// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#splash{
    
    line-height: 26px;
    margin-top: 104px;
    text-align: center;
    /* font-style: italic; */
    font-size: 0.98em;
    max-width: 653px;
    background-color: white;
    padding: 25px;
    border-radius: 14px;
    position:absolute;
    left:auto;
    top:30px;
   
}`, "",{"version":3,"sources":["webpack://./src/components/splash/style.css"],"names":[],"mappings":"AAAA;;IAEI,iBAAiB;IACjB,iBAAiB;IACjB,kBAAkB;IAClB,wBAAwB;IACxB,iBAAiB;IACjB,gBAAgB;IAChB,uBAAuB;IACvB,aAAa;IACb,mBAAmB;IACnB,iBAAiB;IACjB,SAAS;IACT,QAAQ;;AAEZ","sourcesContent":["#splash{\r\n    \r\n    line-height: 26px;\r\n    margin-top: 104px;\r\n    text-align: center;\r\n    /* font-style: italic; */\r\n    font-size: 0.98em;\r\n    max-width: 653px;\r\n    background-color: white;\r\n    padding: 25px;\r\n    border-radius: 14px;\r\n    position:absolute;\r\n    left:auto;\r\n    top:30px;\r\n   \r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
