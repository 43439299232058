// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
 
.global-status{
        
    align-items:baseline;   
    gap:40px;
    justify-content:center; 
    margin-top:50px;
    
    
    .global-pools{

        width:100%;
    }

}


`, "",{"version":3,"sources":["webpack://./src/components/pools/style.css"],"names":[],"mappings":";;AAEA;;IAEI,oBAAoB;IACpB,QAAQ;IACR,sBAAsB;IACtB,eAAe;;;IAGf;;QAEI,UAAU;IACd;;AAEJ","sourcesContent":["\r\n \r\n.global-status{\r\n        \r\n    align-items:baseline;   \r\n    gap:40px;\r\n    justify-content:center; \r\n    margin-top:50px;\r\n    \r\n    \r\n    .global-pools{\r\n\r\n        width:100%;\r\n    }\r\n\r\n}\r\n\r\n\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
