// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#block-viewer{
    
    max-width:650px;    
    padding:5px;
    background:var(--background-b);
    border-radius:var(--radius);
    display:flex;
    flex-wrap:wrap;
    gap:1px;
}


#blocks-viewer-container{
    
    width:100%
}
`, "",{"version":3,"sources":["webpack://./src/components/blocks-viewer/style.css"],"names":[],"mappings":"AAAA;;IAEI,eAAe;IACf,WAAW;IACX,8BAA8B;IAC9B,2BAA2B;IAC3B,YAAY;IACZ,cAAc;IACd,OAAO;AACX;;;AAGA;;IAEI;AACJ","sourcesContent":["#block-viewer{\n    \n    max-width:650px;    \n    padding:5px;\n    background:var(--background-b);\n    border-radius:var(--radius);\n    display:flex;\n    flex-wrap:wrap;\n    gap:1px;\n}\n\n\n#blocks-viewer-container{\n    \n    width:100%\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
