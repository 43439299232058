import {io} from"socket.io-client";

const socket  = io("ws.twolate.com", { transports : ['websocket'] });

socket.on('connect', () => {
     
    console.log("ws: you are connected");
});

socket.on('connect_error', err => {

  console.log(err);
});

socket.on('disconnect', reason => { });
    
const ws = {        
    
    listen: () => {
        
        return socket;
    }
};

export default ws;