import './style.css';
import Button   from '../../elements/button-fat/button-fat';
import Api      from '../../includes/api';
import User     from './user';
import Auth     from './auth';
import UserNav  from '../user-navigation-links/user-navigation-links';
import React, { useState, useEffect }    from 'react';

export default (props) => {
   
    const [is_logged_in, setIsLoggedIn]     = useState(null);
    const [events, setEvents]               = useState([]);
    const [show_payment, setShowPayment]    = useState(null);
    
    useEffect( () => {

        isLoggedIn();   
        getUserEvents();
        
    }, []);
    
    const isLoggedIn = async () => {
        
        const is_logged_in = await Api.get("user");
        
        if( !is_logged_in.error && is_logged_in.content){
            
            setIsLoggedIn(true);
        }
        else{
            
            setIsLoggedIn(false);
        }
    };
    
    const getUserEvents = async () => {
        
        const res       = [];
        const events    = await Api.get("user/events");
      
        for(let event of events.content){
            
            event.data = JSON.parse(event.event);
                
            res.push(event);
        }
      
        setEvents(res);
    };       
 
    const formatDate = (dateTime) => {
        
        const date = new Date(dateTime);
        
        return date.toLocaleDateString()+" "+ date.toLocaleTimeString();
    };
    
    const showPayment = async (event_id, event_payment) => {
        
        const payment = await Api.get("user/event/payment", {event:event_id});
        
        setShowPayment({id:event_id, payment:payment.content});
    };
    
    const drawPayment = () => {
        
        const res       = [];
        const method    = show_payment.payment.method;
        
        res.push(<div><a href='#' class="selected" onClick={(e) => setShowPayment(null)}>Hide payment</a></div>)
        
        if(method === "algorand"){
            
            res.push(
                <>
                <p>Method: Algorand</p>
                <div>Transaction: <a target="_blank" href={"https://allo.info/tx/"+show_payment.payment.proof} alt="View transaction on Allo">{show_payment.payment.proof}</a></div>
                </>
            );            
        }
        else if(method === "stripe"){
            res.push(
                <>
                <p>Method: Stripe</p>
                <div>Payment id: {show_payment.payment.proof}</div>
                </>
            );          
        }
        
        return res;
    };
    
    return ( 
        <>
        <UserNav />
        {
        events.map(event => {
        
            return  <div class="white-container user-event">
                        <date><i class="fa fa-clock"></i> From {formatDate(event.data.start.dateTime)} to {formatDate(event.data.end.dateTime)} </date>
                        <p>Summary: {event.data.summary}</p>
                        <p>Location: {event.data.location}</p>
                        { 
                            show_payment && show_payment.id === event.id ?
                            
                            drawPayment()
                            :
                            <div><a href='#' onClick={(e) => showPayment(event.id, event.payment)}>View payment</a></div>
                        }
                    </div>                           
        })
        }
        </>
    );
} 