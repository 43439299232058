// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.toggle{

    width: 40px;
    border-radius: 15px;
    padding: 1px;
    background-color: white;
    border: 1px solid #dddddd;
    position:relative;
    cursor:pointer;
    
    .selector{

        border-radius: 50%;
        width: 15px;
        height: 15px;
        border: 1px solid #bdbdbd;
        background: #dfdfdf;
        margin-left:1px;
        transition: margin-left 200ms;
    }
    
    .off{
        
        margin-left:1px;
    }
    
    .on{
        
        margin-left:20px;
        border-color: #588938;
        background: #588938; 
    }
    
}

`, "",{"version":3,"sources":["webpack://./src/elements/toggle/style.css"],"names":[],"mappings":"AAAA;;IAEI,WAAW;IACX,mBAAmB;IACnB,YAAY;IACZ,uBAAuB;IACvB,yBAAyB;IACzB,iBAAiB;IACjB,cAAc;;IAEd;;QAEI,kBAAkB;QAClB,WAAW;QACX,YAAY;QACZ,yBAAyB;QACzB,mBAAmB;QACnB,eAAe;QACf,6BAA6B;IACjC;;IAEA;;QAEI,eAAe;IACnB;;IAEA;;QAEI,gBAAgB;QAChB,qBAAqB;QACrB,mBAAmB;IACvB;;AAEJ","sourcesContent":[".toggle{\n\n    width: 40px;\n    border-radius: 15px;\n    padding: 1px;\n    background-color: white;\n    border: 1px solid #dddddd;\n    position:relative;\n    cursor:pointer;\n    \n    .selector{\n\n        border-radius: 50%;\n        width: 15px;\n        height: 15px;\n        border: 1px solid #bdbdbd;\n        background: #dfdfdf;\n        margin-left:1px;\n        transition: margin-left 200ms;\n    }\n    \n    .off{\n        \n        margin-left:1px;\n    }\n    \n    .on{\n        \n        margin-left:20px;\n        border-color: #588938;\n        background: #588938; \n    }\n    \n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
