import './style.css';
import ButtonFat from '../button-fat/button-fat';

export default (props) => {
   
    const onClick = () => {
      
        const url = new URL("/profile", window.location.origin);
        
        if(props.path){
            
            url.searchParams.set("path", props.path);
        }
        
        window.location = url;
        
    };
    
    return (                 
        <div class="white-container must-login">
            You must login to continue
            <p>
                <ButtonFat value="Login" onClick={onClick}/>
            </p>
        </div>
    );
} 