import './App.css';
import React, { useState, useEffect,useRef }    from 'react';
import BlocksViewer     from "./components/blocks-viewer/blocks-viewer";
import TopBar           from "./components/top-bar/top-bar";
import Home             from "./components/home/home";
import Admin            from "./components/admin/admin";
import Doc              from "./components/doc/doc";
import AddressStats     from "./components/address-activity/address-activity";
import Activity         from "./components/activity/activity";
import Menu             from "./components/menu/menu";
import Api              from "./includes/api";

import { WalletProvider, useInitializeProviders, PROVIDER_ID } from '@txnlab/use-wallet'
import { DeflyWalletConnect } from '@blockshake/defly-connect'
import { PeraWalletConnect } from '@perawallet/connect'
import { DaffiWalletConnect } from '@daffiwallet/connect'
import LuteConnect from 'lute-connect'

import { BrowserRouter, Routes, Route } from "react-router-dom";

function App() { 

   const providers = useInitializeProviders({
        providers: [
            
            { id: PROVIDER_ID.DEFLY, clientStatic: DeflyWalletConnect },
            { id: PROVIDER_ID.PERA, clientStatic: PeraWalletConnect },
            { id: PROVIDER_ID.DAFFI, clientStatic: DaffiWalletConnect },
            { id: PROVIDER_ID.EXODUS },
            {
              id: PROVIDER_ID.LUTE,
              clientStatic: LuteConnect,
              clientOptions: { siteName: 'AlgorandCulture' }
            }
        ],
        nodeConfig: {
            network: 'testnet',
            nodeServer: 'https://testnet-api.algonode.cloud',
            nodeToken: '',
            nodePort: '443'
        }
    });
    
    return (
        <>
        <WalletProvider value={providers}>
       
        <TopBar />
        <header>    
            <div id="logo"></div> 
            <Menu />
        </header>
        <main>
            <BrowserRouter>    
               <Routes>
                   <Route path="/"      element={<Home />} />            
                   <Route path="/pools" element={<Admin />} />
                   <Route path="/address-stats" element={<AddressStats />} />
                   <Route path="/activity" element={<Activity />} />
                   <Route path="/doc" element={<Doc />} />
               </Routes>         
            </BrowserRouter>
        </main>  
         
        </WalletProvider>
        <footer></footer>        
        </>
    );

}

export default App;