import './style.css';
import Api      from '../../includes/api';
import parse    from 'html-react-parser';
import TinyMce  from "../tiny-mce/tiny-mce";
import React, { useState, useEffect,useRef }    from 'react';

import { Editor } from '@tinymce/tinymce-react';

export default (props) => {
     
    const [article, setArticle]     = useState({});
    const [is_admin, setIsAdmin]    = useState(false);
    const [is_saving, setIsSaving]  = useState(false);
    
    useEffect( () => {
                       
        const title = window.location.pathname.split("/blog/");
     
        loadArticle(title[1]);
        isAdmin();
        
    }, []);
    
    const isAdmin = async () => {
        
        const user = await Api.get("user");
        
        if( !user.error && user?.content?.is_admin){
            
            setIsAdmin(true);
        }
    };
    
    const loadArticle = async (title) => {
        
        const articles = await Api.get("blog/article", {title:title});
        
        setArticle(articles.content);
    };
    
    const drawDate = (date) => {

        return new Date( parseInt(date) ).toLocaleString();
    };
 
    const onChangeArticleTitle = async (e) => {
        
        article.title = e.target.value;
        
        setArticle({...article});
    };
    
    const onSaveArticle = async (content) => {
        
        setIsSaving(true);
        
        const articles = await Api.post("blog/article/update", {id:article.id, content:content, title:article.title});
        
        setIsSaving(false);
    };
    
    return (    
        <>             
        <article id="blog-article" class="white-container">
            {
                is_admin ?
                <input type="text" value={article.title} id="title-admin-edit-input" onChange={onChangeArticleTitle}/>
                :                        
                <h1 id="title">{article.title}</h1>
            }
            <date><i class="fa fa-clock"></i> {drawDate(article.ts)}</date>
            <div id="content">
            {
                is_admin ? 
                <TinyMce content={article.content} onSave={onSaveArticle} saving={is_saving} />
                :
                article.content ? parse(article.content) : ""
            }
            </div>
        </article>
        </>
    );
} 