import './style.css';
import ButtonFat    from '../../elements/button-fat/button-fat';
import Loader       from '../../elements/loader/loader';
import Api          from '../../includes/api';
import React, { useState, useEffect }    from 'react';

export default (props) => {
     
    const [is_logged_in,    setIsLoggedIn]      = useState(false);  
    const [order,           setOrder]           = useState(null);
    const [order_not_found, setOrderNotFound]   = useState(false);
    
    useEffect( () => {

        isLoggedIn();
        processOrderId();
                
    }, []);        
    
    const isLoggedIn = async () => {
        
        const is_logged_in = await Api.get("user");
        
        if( !is_logged_in.error && is_logged_in.content){
            
            setIsLoggedIn(true);                                   
        }
        else{
         
            setIsLoggedIn(false);
        }
    }; 
    
    const processOrderId = async() => {
        
        const query     = new URLSearchParams(window.location.search);
        const order_id  = query.get("order");        
        const confirm   = await Api.get("order/success", {order:order_id});
        
        if( !confirm.error ){
            
            localStorage.removeItem("store-basket");
            
            setOrder(confirm.content);
        }
        else{
            
            setOrderNotFound(true);
        }
        
    };
   

    
    return ( 
        <>
        
        <div id="store-payment-success" class="white-container">  
            {order === null ?                           <Loader /> : ""}
            {order_not_found ?                          <div>Invalid order</div> : ""}
            {order && order.status === null ?           <div>Your payment was received and your order is now going to be processed.</div> : ""}
            {order && order.status === "in_process" ?   <div>Your order is currently been processed</div> : ""}
            {order && order.status === "sent" ?         <div>Your order was sent</div> : ""}   
            {order && is_logged_in ?                    <div id="button-dashboard"><ButtonFat value="View the order on my dashboard" /></div> : ""}
            {order && !is_logged_in ?                   <div id="button-dashboard"><ButtonFat value="Login to my dashboard" /></div> : ""}
        </div>
        </>
    );
} 