import './style.css';
import Toggle       from '../../elements/toggle/toggle';
import ButtonFat    from '../../elements/button-fat/button-fat';
import Api          from '../../includes/api';
import React, { useState, useEffect, useRef }    from 'react';

export default (props) => {
       

    const drawChainsSelect = (selected_chain, chains) => {
        
        const res = [];
        
        for(const chain of chains){
            
            res.push(<option value={chain.id} selected={chain.id === selected_chain}>{chain.chain}</option>);
        }
        
        return <select defaultValue={selected_chain} onChange={onChangeSelectedChain}>{res}</select>
    };
    
    const onChangeSelectedChain = async(e) => {
        
        const chain = props.chains[e.target.selectedIndex];
        
        props.onChangeChain(props.id, chain.id);
        //setSelectedChain(selected.id);
    };
    
    const onSave = () => {
                
        props.onSave(props.id, props.chain);
    };
    
    const onRemove = () => {
        
        props.onRemove(props.id, props.chain);
    };
       
       
    return (                 
                                       
        <div class="pending-meme-panel">             
            {drawChainsSelect(props.chain, props.chains)}
            <ButtonFat value="Save" onClick={onSave} />
            <ButtonFat value="Remove" onClick={onRemove} />            
       </div>      
    );
} 