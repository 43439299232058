import './style.css';
import Api                                      from '../../includes/api';
import ButtonFat                                from '../../elements/button-fat/button-fat';
import MustLogin                                from '../../elements/must-login/must-login';
import PaymentPanel                             from '../payment-panel/payment-panel'
import React, { useState, useEffect,useRef }    from 'react';

export default (props) => {
   
    const [event_successfully_published,    setEventSuccessfullyPublished]  = useState(false);
    const [payment_data,                    setPaymentData]                 = useState({});
    const [is_logged_in,                    setIsLoggedIn]                  = useState(null);
    const [show_payment_panel,              setShowPaymentPanel]            = useState(false);
    const [event, setEvent] = useState({
       
        summary:     "",
        location:    "",
        description: "",
        start:       "",
        end:         ""       
    });
    
    const [errors, setErrors] = useState({
       
        summary:     null,
        location:    null,
        description: null,
        start:       null,
        end:         null       
    });
    
    useEffect(()=>{
        
        isLoggedIn();
        
    }, []);
    
    const isLoggedIn = async () => {
        
        const is_logged_in = await Api.get("user");
        
        if( !is_logged_in.error && is_logged_in.content){
            
            setIsLoggedIn(true);
        }
        else{
         
            setIsLoggedIn(false);
        }
    };  
    
    const onChange = (e, entry) => {
        
        event[entry] = e.target.value;
                
        setEvent(event);
    };
    
    const onClickSave = async () => {
        
        const now       = new Date();
        const errors    = {
       
            summary:     null,
            location:    null,
            description: null,
            start:       null,
            end:         null       
        };

        if(event.summary.trim().length < 1){
            
            errors.summary = "The summary is too short";
        }
        
        if(event.summary.trim().length === 0){
            
            errors.summary = "The summary is missing";
        }
        
        if(event.location.trim().length < 20){
            
            errors.location = "The location is too short";
        }
        
        if(event.location.trim().length === 0){
            
            errors.location = "The location is missing";
        }
        
        if(event.description.trim().length < 20){
            
            errors.description = "The description is too short";
        }
        
        if(event.description.trim().length === 0){
            
            errors.description = "The description is missing";
        }
                
        if(new Date(event.start) < now){
            
            errors.start = "The start date is already past";
        }
        
        if(event.start.trim().length === 0){

            errors.start = "The start date is missing";
        }
        
        if(new Date(event.end) <= new Date(event.start)){
            
            errors.end = "The end date cannot be smaller than the start date";
        }
        
         if(event.end.trim().length === 0){
            
            errors.end = "The end date is missing";
        }
        
        setErrors(errors);
        
        const get_errors = Object.values(errors).filter( error => error !== null);
        
        let format_event    = {...event}
        const timezone      = Intl.DateTimeFormat().resolvedOptions().timeZone;
        let offset          = () => {
            
                  
            const offset            = (new Date).getTimezoneOffset();
            const hours             = Math.floor(Math.abs(offset) / 60);
            const minutes           = Math.abs(offset) % 60;
            const sign              = offset <= 0 ? '+' : '-';
            const formatted_hours    = String(hours).padStart(2, '0');
            const formatted_minutes  = String(minutes).padStart(2, '0');
            
            return sign+formatted_hours+":"+formatted_minutes;
        };
        
        
        format_event.start  = {dateTime:format_event.start+":00"  + offset(), timeZone:timezone};
        format_event.end    = {dateTime:format_event.end+":00"    + offset(), timeZone:timezone};
          
        if(get_errors.length === 0){
                      
            const save_event = await Api.post("calendar/event", {event:format_event});
           
            if(save_event?.error === false){
                           
                //setEventSuccessfullyPublished(true);
                setShowPaymentPanel(true);
                setPaymentData(save_event.content);
            }
        }
    };
    
    
    const drawError = (error) => {
        
        return <div class="error">{error}</div>
    };
    
    
    const onClickBackToCalendar = () => {
        
        window.location.pathname = "/events";
    };
    
    
    return ( 
        <>
            {
                is_logged_in === false ? <MustLogin path="/events/schedule" /> : ""
            }            
            
            {
                is_logged_in === true ?
                <>
                <div class="sub-nav"><a href="/events">Events</a> / Schedule a new event</div>
                <div class="white-container" id="schedule-event">
                {
                    event_successfully_published ? 
                    <div id="success-publish-message">
                        <p>The event was successfully added to our calendar.</p>    
                        <p><ButtonFat value="Back to the calendar" onClick={onClickBackToCalendar} /></p>
                    </div>

                    :        
                    <>
                    <label>
                        Event summary
                        {errors.summary ? drawError(errors.summary) : ""}
                        <input class="large-input" type="text" onChange={e => onChange(e, "summary")} />
                    </label>        


                    <p>
                    <label>
                        Location
                        {errors.location ? drawError(errors.location) : ""}
                        <input class="large-input" type="text" onChange={e => onChange(e, "location")} />
                    </label> 
                    </p>

                    <p>
                    <label>
                        Event description
                        {errors.description ? drawError(errors.description) : ""}
                        <textarea  onChange={e => onChange(e, "description")} />
                    </label>
                    </p>

                    <p>
                    <label>
                        Date start
                        {errors.start ? drawError(errors.start) : ""}
                        <input type="datetime-local"  onChange={e => onChange(e, "start")} />
                    </label>
                    </p>

                    <p>
                    <label>
                        Date end
                        {errors.end ? drawError(errors.end) : ""}
                        <input type="datetime-local"  onChange={e => onChange(e, "end")} />
                    </label>
                    </p>

                    {show_payment_panel ? <PaymentPanel payment_data={payment_data} /> : <ButtonFat value="Schedule this event now" onClick={onClickSave} />}  
                    </>
                }
                </div> 
                </>
                : ""
            }
        </>
    );
} 