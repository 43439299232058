import './style.css';
import Api  from '../../includes/api';
import React, { useState, useEffect,useRef }    from 'react';

export default (props) => {
     
    const [articles_headers, setArticlesHeaders] = useState([]);
    
    useEffect( () => {
        
        loadArticlesHeaders();
        
    }, []);
    
    const loadArticlesHeaders = async () => {
        
        const articles = await Api.get("blog/articles/headers");
        
        setArticlesHeaders(articles.content);
    };
    
    const drawDate = (date) => {

        return new Date( parseInt(date) ).toLocaleString();
    };
    
    const showArticle = (title) => {
        
        window.location.pathname = "/blog/"+title;
    };
    
    return (                 
        <div id="blog-articles-headers">
            {
                articles_headers.map(article => {
                    
                    return  <div class="white-container" id="header" onClick={(e) => showArticle(article.title_as_url)}>
                                <div id="title">{article.title}</div>
                                <date><i class="fa fa-clock"></i> {drawDate(article.ts)}</date>
                            </div>
                })
            }
        </div>
    );
} 