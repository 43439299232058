import './style.css';

export default (props) => {
    
    return (
        <>
            <div class="stats-value">{new Intl.NumberFormat().format(props.value)}</div>
            <div class="stats-title">{props.title}</div>
        </>
    );
}
