import "./style.css";
import { Editor }   from '@tinymce/tinymce-react';
import ButtonFat    from '../../elements/button-fat/button-fat';
import Loader       from "../../elements/loader/loader";
import React, { useState, useEffect, useRef } from 'react';

export default (props) => {
        
    const editorRef = useRef(null);
    
    const log = () => {
        
        if (editorRef.current) {

          props.onSave(editorRef.current.getContent());
        }
    };
  
  return (
    <>
    <Editor
      apiKey='u756civci0nl0ccrucezbwe48gh452xrk3d3zed42d4jegt9'
      onInit={(_evt, editor) => editorRef.current = editor}
      init={{
        plugins: 'anchor autolink charmap codesample emoticons image link lists media searchreplace table visualblocks wordcount linkchecker',
        toolbar: 'undo redo | blocks | bold italic underline strikethrough | link image media table mergetags | addcomment showcomments | spellcheckdialog a11ycheck typography | align lineheight | checklist numlist bullist indent outdent | emoticons charmap | removeformat',
        tinycomments_mode: 'embedded',
       
        ai_request: null,
      }}
      initialValue={props.content}
    />
    <div id="save-btn">{props.saving ? <Loader /> : <ButtonFat value="Save" onClick={log} />}</div>
    </>
  );
}