import './style.css';
import React, { useState, useEffect,useRef }    from 'react';

export default (props) => {
     
    
    const onChangeState = () => {
        
        const state = props.state === "off" ? "on" : "off";
                       
        props.onChange(state, props.id);
    };
    
    return (    
        <div class="toggle-container" onClick={onChangeState}>
            <div class="toggle" >
                <div class={'selector ' + props.state} ></div>
            </div>
            {
                props.children ? 
                <div class={props.state === "on" ? "selected-children" : ""}>{props.children}</div> 
                : 
                ""
            }
        </div>
    );
} 