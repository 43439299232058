import './style.css';
import Api                                      from '../../includes/api';
import ButtonFat                                from '../../elements/button-fat/button-fat';
import React, { useState, useEffect,useRef }    from 'react';
import { useWallet }                            from '@txnlab/use-wallet'

export default (props) => {
   
    const [selected_method, setSelectedMethod]          = useState(0);
    const { providers, activeAccount,signTransactions } = useWallet();
    const [selected_wallet, setSelectedWallet]          = useState(null);
    
    useEffect(()=>{               
        
        console.log(props);
    }, []);
    
    useEffect(()=>{
               
        
    }, [activeAccount]);
  
    const getProviderFromId = (id) => {
        
        const res = providers.filter( provider => provider.metadata.id === id );
        
        return res[0];
    };
    
    const drawWallets = () => {

        const res = [];
        
        for(const key in providers){
            
           const wallet = providers[key];
           
            if(!wallet){
               
               continue;
            }
 
            res.push(
                <div id="wallet" onClick={(e)=> connectWallet(wallet)}>                    
                    <div id="icon"><img src={wallet.metadata.icon} height="50"/></div>
                    <div id="title">{wallet.metadata.name}</div>
                </div>
            );
        }
        
        return <div id="algorand-wallets">{res}</div>
    };
    
    const connectWallet = (wallet) => {
        
        wallet.connect();
        
        setSelectedWallet(wallet);
    };
    
    const resetProviders = async () => {
        
        selected_wallet.disconnect();
        
        setSelectedWallet(null);        
    };
    
    const onPayWithWallet = async () => {
        
        const current_provider  = getProviderFromId(activeAccount.providerId);
        const algosdk           = current_provider.algosdk;
        const algod             = current_provider.algodClient;
        const suggestedParams   = await algod.getTransactionParams().do();    
        const note              = new Uint8Array(Buffer.from(props.payment_data.wallet.note));
        
        const transaction = algosdk.makePaymentTxnWithSuggestedParamsFromObject({
            from:   activeAccount.address,
            to:     activeAccount.address,
            amount: props.payment_data.wallet.amount,
            note:   note,
            suggestedParams
        });

        const encoded_transaction = algosdk.encodeUnsignedTransaction(transaction)
        const signed_transaction = await signTransactions([encoded_transaction])
        console.log(signed_transaction);
        const send = await Api.post("payment/algorand", {txn:signed_transaction}) ;
        //const waitRoundsToConfirm = 4
        //const { id } = await sendTransactions(signedTransactions, waitRoundsToConfirm)

       
    };
    
    return ( 
        
        <>
        <label>
            Select a payment method
            <p>
            <select value={selected_method} onChange={(e)=>setSelectedMethod(e.target.selectedIndex)}>            
                <option value="0">Stripe - Credit card, Google pay</option>
                <option value="1">Algorand</option>
            </select>
            </p>
        </label>
        <div id="payment-panel">         
            {
                selected_method === 0 ?
                <>
                <div><a href={props.payment_data.stripe}><ButtonFat value="Proceed with the payment" /></a></div>
                    <img id="stripe-logo" src='/pics/pay-stripe.png' alt='pay with stripe'/>
                </>
                :                
                <>
                {
                    activeAccount?.address? 
                    <>
                    <ButtonFat value={"Pay with " + activeAccount.address.substring(0,15) + " ..." } onClick={onPayWithWallet}/> 
                    <p><ButtonFat value="Use a different address" onClick={resetProviders}/></p>
                    </>
                    : 
                    <>{drawWallets()}</>
                }
                </>
                
            }
        </div>       
        </>
              
    );
} 