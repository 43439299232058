// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.button-fat{
    
    background-color:var(--main-green);
    padding:10px 30px;
    font-family: 'League Spartan', sans-serif;
    color:white;   
    border:1px solid transparent;
    cursor:pointer;
    position:relative;
    border-radius:3px;
    transition:background 500ms, color 1000ms, border-color 300ms;
}

.button-fat:hover{
    
    background-color:var(--main-yellow);
    color:var(--main-green);
}

.button-fat:active{
    
   top:2px;
   border-color:var(--main-green);
}

.fullsize{
    
    width:100%
}

`, "",{"version":3,"sources":["webpack://./src/elements/button-fat/style.css"],"names":[],"mappings":"AAAA;;IAEI,kCAAkC;IAClC,iBAAiB;IACjB,yCAAyC;IACzC,WAAW;IACX,4BAA4B;IAC5B,cAAc;IACd,iBAAiB;IACjB,iBAAiB;IACjB,6DAA6D;AACjE;;AAEA;;IAEI,mCAAmC;IACnC,uBAAuB;AAC3B;;AAEA;;GAEG,OAAO;GACP,8BAA8B;AACjC;;AAEA;;IAEI;AACJ","sourcesContent":[".button-fat{\n    \n    background-color:var(--main-green);\n    padding:10px 30px;\n    font-family: 'League Spartan', sans-serif;\n    color:white;   \n    border:1px solid transparent;\n    cursor:pointer;\n    position:relative;\n    border-radius:3px;\n    transition:background 500ms, color 1000ms, border-color 300ms;\n}\n\n.button-fat:hover{\n    \n    background-color:var(--main-yellow);\n    color:var(--main-green);\n}\n\n.button-fat:active{\n    \n   top:2px;\n   border-color:var(--main-green);\n}\n\n.fullsize{\n    \n    width:100%\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
