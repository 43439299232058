// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#top-bar{
    
   
    height:65px;
    opacity:0.3;   
    display:flex;
    justify-content:end;
    gap:var(--gap);
    transition: opacity 0.4s; 
    
    .block{
        
        padding:20px;
        font-size:1.5em;
    }   
    
    .block:last-of-type{
        
        margin-right:var(--gap)
    }
    
    #discord{
        
        color:#6056f1
    }
}

#top-bar:hover{
    
    opacity:0.9
}`, "",{"version":3,"sources":["webpack://./src/components/top-bar/style.css"],"names":[],"mappings":"AAAA;;;IAGI,WAAW;IACX,WAAW;IACX,YAAY;IACZ,mBAAmB;IACnB,cAAc;IACd,wBAAwB;;IAExB;;QAEI,YAAY;QACZ,eAAe;IACnB;;IAEA;;QAEI;IACJ;;IAEA;;QAEI;IACJ;AACJ;;AAEA;;IAEI;AACJ","sourcesContent":["#top-bar{\r\n    \r\n   \r\n    height:65px;\r\n    opacity:0.3;   \r\n    display:flex;\r\n    justify-content:end;\r\n    gap:var(--gap);\r\n    transition: opacity 0.4s; \r\n    \r\n    .block{\r\n        \r\n        padding:20px;\r\n        font-size:1.5em;\r\n    }   \r\n    \r\n    .block:last-of-type{\r\n        \r\n        margin-right:var(--gap)\r\n    }\r\n    \r\n    #discord{\r\n        \r\n        color:#6056f1\r\n    }\r\n}\r\n\r\n#top-bar:hover{\r\n    \r\n    opacity:0.9\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
