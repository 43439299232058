import './style.css';

export default (props) => {
   
    return (                 
        <button 
            onClick = {props.onClick}
            class   = {props.width == "100%" ? "button-fat fullsize" : "button-fat"}>
            {props.value}
        </button>
    );
} 