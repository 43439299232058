// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.toggle-container{
    
    display:flex;
    gap:5px;
    cursor: pointer;
    width:fit-content;
    
    .toggle{

        width: 35px;
        height: 16px;
        border-radius: 3px;
        padding: 1px;
        background-color: white;
        border: 1px solid #dddddd;
        position:relative;        
        margin-top:1px;
        
        .selector{

            border-radius: 2px;
            width: 12px;
            height: 12px;
            border: 1px solid #bdbdbd;
            background: #dfdfdf;               
            transition: margin-left 200ms;
        }

        .on{

            margin-left:19px;
            border-color: var(--color-a);
            background: var(--color-a); 
        }   
               
    }
    
    .selected-children{

        color: var(--color-a);
    }
}
`, "",{"version":3,"sources":["webpack://./src/elements/toggle/style.css"],"names":[],"mappings":"AAAA;;IAEI,YAAY;IACZ,OAAO;IACP,eAAe;IACf,iBAAiB;;IAEjB;;QAEI,WAAW;QACX,YAAY;QACZ,kBAAkB;QAClB,YAAY;QACZ,uBAAuB;QACvB,yBAAyB;QACzB,iBAAiB;QACjB,cAAc;;QAEd;;YAEI,kBAAkB;YAClB,WAAW;YACX,YAAY;YACZ,yBAAyB;YACzB,mBAAmB;YACnB,6BAA6B;QACjC;;QAEA;;YAEI,gBAAgB;YAChB,4BAA4B;YAC5B,0BAA0B;QAC9B;;IAEJ;;IAEA;;QAEI,qBAAqB;IACzB;AACJ","sourcesContent":[".toggle-container{\n    \n    display:flex;\n    gap:5px;\n    cursor: pointer;\n    width:fit-content;\n    \n    .toggle{\n\n        width: 35px;\n        height: 16px;\n        border-radius: 3px;\n        padding: 1px;\n        background-color: white;\n        border: 1px solid #dddddd;\n        position:relative;        \n        margin-top:1px;\n        \n        .selector{\n\n            border-radius: 2px;\n            width: 12px;\n            height: 12px;\n            border: 1px solid #bdbdbd;\n            background: #dfdfdf;               \n            transition: margin-left 200ms;\n        }\n\n        .on{\n\n            margin-left:19px;\n            border-color: var(--color-a);\n            background: var(--color-a); \n        }   \n               \n    }\n    \n    .selected-children{\n\n        color: var(--color-a);\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
