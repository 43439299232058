import './style.css';
import Toggle       from '../../elements/toggle/toggle';
import ButtonFat    from '../../elements/button-fat/button-fat';
import Loader       from '../../elements/loader/loader';
import Api          from '../../includes/api';

import React, { useState, useEffect }    from 'react';

export default (props) => {
         
    const [is_logged_in,    setIsLoggedIn]              = useState(false);    
    const [is_admin,        setIsAdmin]                 = useState(false);
    const [orders,          setOrders]                  = useState([]);
    const [show_delivery_number, setShowDeliveryNumber] = useState([]);
    const [show_save_changes, setShowSaveChanges]       = useState([]);
    
    const [changed_status, setChangedStatus]            = useState({});
    
    useEffect( () => {

        isLoggedIn();
        
    }, []);
    
    
    const isLoggedIn = async () => {
        
        const is_logged_in = await Api.get("user");
        
        if( !is_logged_in.error && is_logged_in.content){
            
            setIsLoggedIn(true);
            
            if(is_logged_in.content.is_admin){
                
                setIsAdmin(true);
                
                listOrders();
            }
        }
        else{
         
            setIsLoggedIn(false);
        }
    };  
      
      
    const listOrders = async () => {
               
        const list = await Api.get("store/pending-orders");
       
        setOrders(list.content);
    };


    const drawBasket = (basket) => {
        
        const res = [];
       
        for(const product of basket){
                  
            res.push(
                    
                <div>{product.quantity} x {product.title}</div>
            );
        }
        
        return res;
    };
        
    
    const drawAddress = (address) => {
        
        const res = [];
       
        res.push(
            <>   
            <div>{address.firstname} {address.lastname}</div>
            <div>{address.street}</div>
            <div>{address.zipcode} {address.city}</div>
            <div>{address.country}</div>
            </>
        );
        
        return res;
    };        
    
    const drawChangeStatusSelect = (order) => {
        
        let order_status = "pending";
        
        if(order.processing_ts){
            
            order_status = "processing";
        }
        
        const res = <select onChange={(e) => onChangeStatus(e,order)}>
                        {order_status === "pending" ? <option value="pending" selected={!order.processed_ts && !order.processing_ts}>Pending</option> : ""}
                        <option value="processing" selected={!order.processed_ts && order.processing_ts}>Processing</option>
                        <option value="processed">Processed</option>                                    
                    </select>
        
        return res;
    }
    
    const isProcessedStatusSelected = (order_id, changed_status) => {
        
        const is_processed_selected = changed_status[order_id] && changed_status[order_id].status === "processed";
        
        return is_processed_selected;
    }
    
    
    const isStatusChanged = (order_id) => {
        
        let order_status        = "pending";        
        const order             = orders.filter(order => order.id === order_id)[0];
        const new_order_status  = changed_status[order_id]?.status;
        
        if(order.processing_ts){
            
            order_status = "processing";
        }
        
        const res = new_order_status && order_status !== new_order_status;;               
        
        return res;
    }
    
    
    const onChangeDeliveryNumber = (e, order_id) => {
        
        const status = {...changed_status};
        
        status[order_id].delivery_number = e.target.value;
        
        setChangedStatus(status);        
    }
    
    
    const onSaveChanges = async (order_id) => {
        
        const status = changed_status[order_id];
        
        await Api.post("store/order/status", {id:order_id, status:status});
       
        delete changed_status[order_id];
        
        setChangedStatus(changed_status);
    }
    
    
    const onChangeStatus = (e, order) => {
        
        const status = {...changed_status};
        
        status[order.id] = {status:e.target.value};
       
        setChangedStatus(status);              
    }
    
    return ( 
       
        <div id="store-orders-container">
        { 
            is_admin ? 
           
            orders.map(order => {

                return  <div class="white-container order">
                            <div class="order-id">Order #{order.id}</div>
                            <div class="products"><p>Products:</p>{drawBasket(JSON.parse(order.basket))}</div>                           
                            <div class="address"><p>Delivery address:</p>{drawAddress(JSON.parse(order.address))}</div>
                            <div class="status"><p>Status:</p> 
                                <form>
                                { drawChangeStatusSelect(order) }                               
                                {
                                    isProcessedStatusSelected(order.id, changed_status) ? 
                                    <input type="text" onChange={(e) => onChangeDeliveryNumber(e, order.id)} class="input-delivery-number" placeholder="Delivery number ..."/> 
                                    : 
                                    ""
                                }
                                </form>
                            </div>
                            {
                                isStatusChanged(order.id) ? 
                                <div class="save-changes">
                                    <ButtonFat value="Save changes" onClick={(e) => onSaveChanges(order.id)} />
                                </div> 
                                : 
                                ""
                            }
                        </div>
            })
            : 
            ""             
        }  
        </div>
     
    );
} 