// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#services-container{
    
    p{
        margin-top:20px;
        margin-bottom:0px;
    }
    
    .service-toogle{

        display:flex;
        margin-bottom:20px;
        
        .description{
            margin-top:3px;
            margin-left:15px;
            transition: color 200ms;
        }
        .off{

            color:#bfbfbf
        }

        .on{

            color:var(--main-green)
        }
    }
    
    #informations{
        
        margin-top:30px;
        
        label{
            
            display:block;
            margin-bottom:35px;
        }
        
        .toggles{
            
        }
        
        #email_address{

            width:250px;
        }

        #message{

            width:100%;
            height:200px;
        }
    }
}`, "",{"version":3,"sources":["webpack://./src/components/services/style.css"],"names":[],"mappings":"AAAA;;IAEI;QACI,eAAe;QACf,iBAAiB;IACrB;;IAEA;;QAEI,YAAY;QACZ,kBAAkB;;QAElB;YACI,cAAc;YACd,gBAAgB;YAChB,uBAAuB;QAC3B;QACA;;YAEI;QACJ;;QAEA;;YAEI;QACJ;IACJ;;IAEA;;QAEI,eAAe;;QAEf;;YAEI,aAAa;YACb,kBAAkB;QACtB;;QAEA;;QAEA;;QAEA;;YAEI,WAAW;QACf;;QAEA;;YAEI,UAAU;YACV,YAAY;QAChB;IACJ;AACJ","sourcesContent":["#services-container{\r\n    \r\n    p{\r\n        margin-top:20px;\r\n        margin-bottom:0px;\r\n    }\r\n    \r\n    .service-toogle{\r\n\r\n        display:flex;\r\n        margin-bottom:20px;\r\n        \r\n        .description{\r\n            margin-top:3px;\r\n            margin-left:15px;\r\n            transition: color 200ms;\r\n        }\r\n        .off{\r\n\r\n            color:#bfbfbf\r\n        }\r\n\r\n        .on{\r\n\r\n            color:var(--main-green)\r\n        }\r\n    }\r\n    \r\n    #informations{\r\n        \r\n        margin-top:30px;\r\n        \r\n        label{\r\n            \r\n            display:block;\r\n            margin-bottom:35px;\r\n        }\r\n        \r\n        .toggles{\r\n            \r\n        }\r\n        \r\n        #email_address{\r\n\r\n            width:250px;\r\n        }\r\n\r\n        #message{\r\n\r\n            width:100%;\r\n            height:200px;\r\n        }\r\n    }\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
