import './style.css';

export default (props) => {

    return (
            
       <div class="loader"></div>
    );
}

