// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#blog-articles-headers{
        
    #header{
        
        width: 60%;
        margin: auto;
        margin-bottom: 15px;
        cursor:pointer;
    }
    
    #header:hover{
        
        border-color:var(--main-green);
        color:var(--main-green)
    }
    
    #title{
        
        font-size:1.3em;
        margin-bottom: 10px;
    }
    
    date{
        
        font-size:0.85em;
        color:#a3a3a3;
    }
}

#blog-article{
    
    width:70%;
    margin:auto;
    
    #title{
        
        font-size: 3.0em;
        margin-bottom: 20px;
        font-weight: normal;
        margin-top: 0px;
        color:var(--main-green)
    }
    
    #content{
        
        margin-top: 30px;
        font-size: 1.15em;
        line-height: 25px;       
    }        
    
    img{
        
        max-width:100%;
        height:auto; 
    }
    
    #title-admin-edit-input{
        
        width: 100%;
        background: white;
        color: black;
        border-color: #eeeeee;
        border-width: 2px;
        margin-bottom:15px;
    }
}`, "",{"version":3,"sources":["webpack://./src/components/blog/style.css"],"names":[],"mappings":"AAAA;;IAEI;;QAEI,UAAU;QACV,YAAY;QACZ,mBAAmB;QACnB,cAAc;IAClB;;IAEA;;QAEI,8BAA8B;QAC9B;IACJ;;IAEA;;QAEI,eAAe;QACf,mBAAmB;IACvB;;IAEA;;QAEI,gBAAgB;QAChB,aAAa;IACjB;AACJ;;AAEA;;IAEI,SAAS;IACT,WAAW;;IAEX;;QAEI,gBAAgB;QAChB,mBAAmB;QACnB,mBAAmB;QACnB,eAAe;QACf;IACJ;;IAEA;;QAEI,gBAAgB;QAChB,iBAAiB;QACjB,iBAAiB;IACrB;;IAEA;;QAEI,cAAc;QACd,WAAW;IACf;;IAEA;;QAEI,WAAW;QACX,iBAAiB;QACjB,YAAY;QACZ,qBAAqB;QACrB,iBAAiB;QACjB,kBAAkB;IACtB;AACJ","sourcesContent":["#blog-articles-headers{\r\n        \r\n    #header{\r\n        \r\n        width: 60%;\r\n        margin: auto;\r\n        margin-bottom: 15px;\r\n        cursor:pointer;\r\n    }\r\n    \r\n    #header:hover{\r\n        \r\n        border-color:var(--main-green);\r\n        color:var(--main-green)\r\n    }\r\n    \r\n    #title{\r\n        \r\n        font-size:1.3em;\r\n        margin-bottom: 10px;\r\n    }\r\n    \r\n    date{\r\n        \r\n        font-size:0.85em;\r\n        color:#a3a3a3;\r\n    }\r\n}\r\n\r\n#blog-article{\r\n    \r\n    width:70%;\r\n    margin:auto;\r\n    \r\n    #title{\r\n        \r\n        font-size: 3.0em;\r\n        margin-bottom: 20px;\r\n        font-weight: normal;\r\n        margin-top: 0px;\r\n        color:var(--main-green)\r\n    }\r\n    \r\n    #content{\r\n        \r\n        margin-top: 30px;\r\n        font-size: 1.15em;\r\n        line-height: 25px;       \r\n    }        \r\n    \r\n    img{\r\n        \r\n        max-width:100%;\r\n        height:auto; \r\n    }\r\n    \r\n    #title-admin-edit-input{\r\n        \r\n        width: 100%;\r\n        background: white;\r\n        color: black;\r\n        border-color: #eeeeee;\r\n        border-width: 2px;\r\n        margin-bottom:15px;\r\n    }\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
