import './style.css';
import { useRef, useEffect }    from 'react';
import Api                      from "../../includes/api";
import Socket                   from "../../includes/ws";

export default (props) => {
    
    const ref_container = useRef(null); // Can be the width it wants
    const ref_scaler    = useRef(null); // Parent of canvas, width rounded to closet 5
                                        // note: canvas needs that parent container ...
    const ref_canvas    = useRef(null); // The canvas
        
    let 
        previous_container_size = null,
        blocks                  = null;

    Socket.listen().onAny((event, ...args) => {

        if(event === "block" && blocks){
            
            const new_block = args[0];
            
            blocks.push(new_block);
            
            draw();
        }
    });
    
    const loadBlocks = async () => {
        
        if( !blocks ){
            
            const get_blocks = await Api.get("blocks");
            
            if(get_blocks.error === false){
                
                blocks = get_blocks.content;
            }
        }
    };
        
    const draw = async () => {
        
        await loadBlocks();
              
        let ctx = ref_canvas.current.getContext("2d");        
        
        ctx.reset();
        
        const block_width       = 5;
        const round             = (num) => Math.floor(num / block_width) * block_width;
        const parent_width      = ref_container.current.offsetWidth;
        const width             = round(parent_width);
        const blocks_per_day    = blocks.length > 26200 ? blocks.length + 500 : 26200;
        const blocks_per_line   = width / block_width;
        const needed_lines      = blocks_per_day / blocks_per_line;
        const height            = needed_lines * block_width;
        
        
        ref_canvas.current.setAttribute('height', round(height));
        ref_canvas.current.setAttribute('width', width);
        ref_scaler.current.setAttribute('width', width);
        
        ctx.clearRect(0, 0, width, height);        

        let current = 0;
        let pos_y   = 0;
        
        for(let x = 0; x < blocks_per_day; x++){
            
            if(current === blocks_per_line){
                
                current = 0;
                pos_y   += block_width;
            }
            
            //let current_line    = parseInt(x / blocks_per_line)  + 1;            
            const block     = blocks[x] || null;
            const pos_x     = current * block_width;            
            ctx.fillStyle   = block ? "#f76a9b" : "#d7d7d7";
            
            ctx.fillRect(pos_x, pos_y, 4, 4);
            
            current++;
        }
    };
    
    
    useEffect(() => {

        setTimeout(() => {
           
            draw();
            
        }, 100);

    }, []);

    return (
        <>
        <div ref={ref_container} id='blocks-viewer-container'>
            <div ref={ref_scaler} id="responsive-container">
                <canvas ref={ref_canvas} id="responsive-canvas"></canvas>
            </div>
        </div>                 
        </>
    );
}
