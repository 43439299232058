import './style.css';
import Toggle from '../../elements/toggle/toggle';
import ButtonFat from '../../elements/button-fat/button-fat';
import Api from '../../includes/api';
import React, { useState, useEffect, useRef }    from 'react';

export default (props) => {     

    const [preview_meme,      setPreviewMeme]   = useState(false);  // Base64 of the preview when submitting a meme
    const [selected_chain,    setSelectedChain] = useState(null);   
    const input_file_ref                        = useRef(0);     

    const onSelectFileToUpload = async (e) => {
                    
        const file          = input_file_ref.current.files[0];//e.target.files[0];  
        const reader        = new FileReader();
        
        if(file){
            
            reader.readAsDataURL(file);

            reader.onload = (e) => {

                setPreviewMeme(e.srcElement.result);
            };
        }
        /*
        const form_data     = new FormData();
     
        form_data.append("picture", file);
        form_data.append("chain", 7);
        
        await Api.post("memes/upload", form_data);
             
         */
    };
    
    const drawChainsDropDownList = (chains) => {
        
        const res = [];
        
        for(const chain of chains){
            
            res.push(<option value={chain.id}>{chain.chain}</option>);
        }
        
        return <select onChange={changeSelectedChain}>{res}</select>
    };
    
    const changeSelectedChain = (e) => {
        
        setSelectedChain(e.target.value);
    };
    
    const onSubmitMeme = async () => {
        
        if(selected_chain){
            
            const file         = input_file_ref.current.files[0];//e.target.files[0];          
            const form_data     = new FormData();

            form_data.append("picture", file);
            form_data.append("chain", selected_chain);

            await Api.post("memes/upload", form_data);               
        }
    };
    
    
    return (                 
            <>
                <input 
                    type        = "file" 
                    id          = "upload-input" 
                    accept      = "image/*" 
                    ref         = {input_file_ref} 
                    onChange    = {onSelectFileToUpload} />
                <ButtonFat value="Submit a meme" onClick={(e) => input_file_ref.current.click()}/>   
                {
                    preview_meme ? 
                        <>
                            <img src={preview_meme} id="preview-meme-picture" /> 
                            <div id="preview-meme-chain">
                                <label>Select the chain this meme is for
                                {drawChainsDropDownList(props.chains)}
                                </label>
                                <ButtonFat value="Send" width="100%" onClick={onSubmitMeme} />
                            </div>
                        </>
                    : 
                        ""
                }
            </>  
    );
} 