// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#user-navigation-links{
    
    margin-bottom:50px;
    
    ul{
        display:flex; 
        gap:25px;
        margin:0px;
        padding:0px;
    }
    
    li{
        
        list-style-type: none;        
    }    
   
}`, "",{"version":3,"sources":["webpack://./src/components/user-navigation-links/style.css"],"names":[],"mappings":"AAAA;;IAEI,kBAAkB;;IAElB;QACI,YAAY;QACZ,QAAQ;QACR,UAAU;QACV,WAAW;IACf;;IAEA;;QAEI,qBAAqB;IACzB;;AAEJ","sourcesContent":["#user-navigation-links{\r\n    \r\n    margin-bottom:50px;\r\n    \r\n    ul{\r\n        display:flex; \r\n        gap:25px;\r\n        margin:0px;\r\n        padding:0px;\r\n    }\r\n    \r\n    li{\r\n        \r\n        list-style-type: none;        \r\n    }    \r\n   \r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
