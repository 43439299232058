import './style.css';
import Api                      from "../../includes/api"
import Contracts                from "../../includes/contracts";
import algosdk                  from "algosdk";
import algostack                from '../../includes/algostack';
import {useState, useEffect}    from 'react';

export default (props) => {
   
    const [is_auth,                 setIsAuth]              = useState(false);
    const [pools,                   setPools]               = useState([]);
    const [pools_from_chain,        setPoolsFromChain]      = useState([]);
    const [show_move_liquidity,     setShowMoveLiquidity]   = useState(null);
    const [liquidity_to_move,       setLiquidityToMove]     = useState(0);
    const [move_liquidity_to_pool,  setMoveLiquidityToPool] = useState(0);
    const [show_move_rewards,       setShowMoveRewards]   = useState(null);
    const [rewards_to_move,       setRewardsToMove]     = useState(0);
    const [move_rewards_to_pool,  setMoveRewardsToPool] = useState(0);
    
    const abi = JSON.parse(process.env.REACT_APP_ABI_POOL);

    const loadPoolsFromDB = async () => {
        
        const res           = [];
        const pools_from_db = await Api.get("pools");
        const pools_id      = Contracts.getPools();
  
        for(const pool_index in pools_id){
            
            const pool_info = pools_from_db.content.filter( entry => entry.index == pool_index );
     
            res.push({
                app:    pools_id[pool_index], 
                index:  pool_info[0].index,
                amount: algosdk.microalgosToAlgos(parseInt(pool_info[0].amount))
            });
        }

        setPools(res);
        
        loadPoolsFromChain();
    };
    
    
    const loadPoolsFromChain = async (index, res = []) => {
        
        const pools     = Contracts.getPools();
        index           = index ? index : 0;        
        const stake     = await Contracts.getPoolStake(pools[index]);
        const balance   = await Contracts.getPoolBalance(pools[index]);        
        res[index]      = {index:index, app:pools[index], stake:stake, balance:balance};
        
              
        if(index < Object.entries(pools).length - 1 ){
            
            index++;

            setTimeout(loadPoolsFromChain, 1000, index, res);
        }
        else{
            
            setPoolsFromChain(res);
        }
    };
    
    
    const onShowMoveLiquidity = (pool_index) => {
        
        setShowMoveLiquidity(pool_index);
        setLiquidityToMove(0);
        setMoveLiquidityToPool(0);
        setShowMoveRewards(null);
    };
    
    
    const onShowMoveRewards = (pool_index) => {
        
        setShowMoveRewards(pool_index);
        setRewardsToMove(0);
        setMoveRewardsToPool(0);
        setShowMoveLiquidity(null);
    };
    
    
    const drawPoolsList = (current_index, target) => {
    
        const res = [];
                        
        for(const pool of pools){
            
            if(pool.index !== current_index){
                
                res.push(<option value={pool.index}>pool {pool.index}</option>);
            }
        }
        
        return target === "rewards" ? <select onChange={e=>setMoveRewardsToPool(e.target.value)}>{res}</select>
                                    : <select onChange={e=>setMoveLiquidityToPool(e.target.value)}>{res}</select>
    };
    
    
    const moveLiquidity = async (from_pool_index) => {
        
        //console.log(from_pool_index, move_liquidity_to_pool, liquidity_to_move);
      
        
        const from_pool         = pools.filter(pool => pool.index == from_pool_index)[0];
        const to_pool           = pools.filter(pool => pool.index == move_liquidity_to_pool)[0];
        const from_pool_address = algosdk.getApplicationAddress(from_pool.app);
        const amount            = algosdk.algosToMicroalgos(parseFloat(liquidity_to_move));
        const address           = "VB3NOP2C6PV4WO5V2XMNPPCWRYFUBJROFRUQDNL4DMASUAMHU4AHJ2DXEQ";
        const composer          = new algosdk.AtomicTransactionComposer();
        const contract          = new algosdk.ABIContract(abi); 
        const params            = await algostack.txns.algod.getTransactionParams().do();
        params.fee              = params.minFee * 5;
        params.flatFee          = true;             
        const signingFunction   = async (unsigned_txns) => {

            return await algostack.txns.signTxns(unsigned_txns);
        }            
   
        composer.addMethodCall({

            appID:      from_pool.app,
            method:     contract.getMethodByName('move_liquidity_to_balancer'),      
            sender:     address, 
            signer:     signingFunction,                
            methodArgs: [
                
                amount,
                to_pool.index
            ], 
            appForeignApps:[

                Contracts.getBalancerAppId(),
                Contracts.getStakerAppId(),
                to_pool.app
            ],     
            suggestedParams: params,
        });               
     
        const result = await composer.execute(algostack.txns.algod, 4);

        console.log(result.methodResults[0].returnValue);
        console.log(result.methodResults[0].txID);         
    };
    
    
    const moveRewards = async (from_pool_index) => {

        const from_pool         = pools.filter(pool => pool.index == from_pool_index)[0];
        const to_pool           = pools.filter(pool => pool.index == move_rewards_to_pool)[0];
        const from_pool_address = algosdk.getApplicationAddress(from_pool.app);
        const amount            = algosdk.algosToMicroalgos(parseFloat(rewards_to_move));
        const address           = "VB3NOP2C6PV4WO5V2XMNPPCWRYFUBJROFRUQDNL4DMASUAMHU4AHJ2DXEQ";
        const composer          = new algosdk.AtomicTransactionComposer();
        const contract          = new algosdk.ABIContract(abi); 
        const params            = await algostack.txns.algod.getTransactionParams().do();
        params.fee              = params.minFee * 5;
        params.flatFee          = true;             
        const signingFunction   = async (unsigned_txns) => {

            return await algostack.txns.signTxns(unsigned_txns);
        }            
   
        composer.addMethodCall({

            appID:      from_pool.app,
            method:     contract.getMethodByName('move_rewards_to_balancer'),      
            sender:     address, 
            signer:     signingFunction,                
            methodArgs: [
               
                amount, 
                to_pool.index
            ], 
            appForeignApps:[

                Contracts.getBalancerAppId(),
                Contracts.getStakerAppId(),
                to_pool.app
            ],     
            suggestedParams: params,
        });               

     
        const result = await composer.execute(algostack.txns.algod, 4);

        console.log(result.methodResults[0].returnValue);
        console.log(result.methodResults[0].txID); 
        
    };
    
    
    useEffect(() => {
    
        
        // Auth
        const auth_token = localStorage.getItem("user");
       
        if(auth_token){
        
            
            Api.setUser(auth_token);
                        
            setIsAuth(true);
        }
        else{
            
            Api.setUser(null);
             
            setIsAuth(false);
        }  
        
        
        loadPoolsFromDB();        
        
    }, []);

       
    
    return(
        <div id="pools-admin">
        
        <table>
            <tr>
                <th>Index</th>
                <th>Contract id</th>
                <th>Liquidity</th>
                <th>Rewards</th>
                <th>Key expiration</th>
                
            </tr>
            {
                pools_from_chain.length === 0 ?
                
                <tr>
                    <td colspan="5"><p>Loading pools data from chain ...</p></td>
                </tr>
                : ""
            }

        {   
            pools_from_chain.map( pool => {
                                 
                return <>
                   
                    <tr>
                        <td>{pool.index}</td>
                        <td>{pool.app}</td>
                        <td>
                            <div class="flex td-with-button">
                                {algosdk.microalgosToAlgos(pool.stake)}
                                <div>
                                    <button 
                                        class   = "small-btn" 
                                        onClick = {e => onShowMoveLiquidity(pool.index)}>
                                        Move
                                    </button>  
                                </div>
                            </div>
                        </td>
                        <td>
                            <div class="flex td-with-button">
                                {algosdk.microalgosToAlgos(pool.balance - pool.stake)}
                                <div>
                                    <button 
                                        class   = "small-btn" 
                                        onClick = {e => onShowMoveRewards(pool.index)}>
                                        Move
                                    </button>  
                                </div>
                            </div>
                        </td>  
                        <td>
                            45 days
                        </td>
                    </tr>                                      
                    {
                        show_move_liquidity == pool.index ?
                            <> 
                            <tr>
                            <td colspan="5">
                                <div class='btns-panel-title'>Move liquidity from pool {pool.index}</div>    
                                <div class="flex btns-panel">
                                    <input type="text" id="move_liquidity_amount" onChange={e => setLiquidityToMove(e.target.value)}/> algos to {drawPoolsList(pool.index, "liquidity")}
                                    <button 
                                    class   = "small-btn" 
                                    onClick = {e => moveLiquidity(pool.index)}>
                                    Proceed
                                    </button>
                                    <button 
                                    class   = "small-btn" 
                                    onClick = {e => setShowMoveLiquidity(null)}>
                                    Cancel
                                    </button>
                                </div>                                    
                            </td>
                            </tr>
                            </> : ""
                    }
                    {
                        show_move_rewards == pool.index ?
                            <> 
                            <tr>
                            <td colspan="5">
                                <div class='btns-panel-title'>Move rewards from pool {pool.index}</div>    
                                <div class="flex btns-panel">
                                    <input type="text" id="move_liquidity_amount" onChange={e => setRewardsToMove(e.target.value)}/> algos to {drawPoolsList(pool.index, "rewards")}
                                    <button 
                                    class   = "small-btn" 
                                    onClick = {e => moveRewards(pool.index)}>
                                    Proceed
                                    </button>
                                    <button 
                                    class   = "small-btn" 
                                    onClick = {e => setShowMoveRewards(null)}>
                                    Cancel
                                    </button>
                                </div>                                    
                            </td>
                            </tr>
                            </> : ""
                    }
                    </>
            })
        }            
        </table>                                    
      
        </div>
    );

}

