import './style.css';
import Toggle from '../../elements/toggle/toggle';
import ButtonFat from '../../elements/button-fat/button-fat';

import React, { useState, useEffect, useRef } from 'react';

export default (props) => {
   
   const [services, setServices] = useState([
       
        {
            id:1,
            name: "Social Media Setup and Optimization"
        },
        {
            id:2,
            name: "Content Management & Engagement"
        },
        ,
        {
            id:3,
            name: "Custom Content Creation"
        }
        ,
        {
            id:4,
            name: "Business Strategy Consultation"
        }
        ,
        {
            id:5,
            name: "SEO & Ad Management"
        }
        ,
        {
            id:6,
            name: "Photography"
        }
        ,
        {
            id:7,
            name: "Logo Design"
        }
        ,
        {
            id:8,
            name: "Basic Website"
        }
        ,
        {
            id:9,
            name: "Advanced Website"
        }
        ,
        {
            id:10,
            name: "E-Commerce Website"
        }
    ]);
        
   const [selected_services,    setSelectedServices]   = useState([]);
   const [email_address,        setEmailAddress]   = useState([]);
   const [message,                  setMessage]   = useState([]);
   
   const onChangeToggle = (state, id) => {
      
       if(state === "on"){
                        
            selected_services.push(id);
        
            setSelectedServices([...selected_services]);
        }
        else{
            
            const new_entries = selected_services.filter(entry => entry !== id);

            setSelectedServices(new_entries);
        }                
   };
   
   const drawToggles = (chains, selected_chains) => {
        
        const res = [];
        
        services.map(service => {

            res.push(<div class="service-toogle">
                        <div>
                            <Toggle 
                                onChange    = {onChangeToggle} 
                                state       = "off"
                                id          = {service.id} />
                        </div>
                        <div  class={selected_services.includes(service.id) ? "description on":"description off"}>
                            {service.name}                                                                                
                        </div>
                      </div>
            );
        });
        
        return res;
    };
    
    const onSend = () => {
        
        
    };
    
   
    return (                 
        <div id="services-container">  
         
           <div class="white-container" id="informations">
            <label>
            Select the service(s) we can help you with:
            <p>
            {drawToggles()}
            </p>
            </label>
            <label>
                Email address
                <p>
                <input 
                    id          = "email_address" 
                    type        = "text" value={email_address} 
                    onChange    = {(e)=> setEmailAddress(e.target.value)}/>
                </p>
            </label>
            <label>
                Phone number
                <p>
                <input 
                    id          = "email_address" 
                    type        = "text" value={email_address} 
                    onChange    = {(e)=> setEmailAddress(e.target.value)}/>
                </p>
            </label>
           
            <label>
                Tell us more about what you need
                <p>
                <textarea 
                    id          = "message" 
                    type        = "text" 
                    value       = {message} 
                    onChange    = {(e) => setMessage(e.target.value)}/>
                </p>
            </label>
            
            <ButtonFat value="Send request" />
           </div>
        </div>                       
    );
} 