import './style.css';
import Toggle       from '../../elements/toggle/toggle';
import ButtonFat    from '../../elements/button-fat/button-fat';
import Api          from '../../includes/api';
import MemeSubmit   from './meme-submit';
import PendingPanel from './pending-panel';
import Loader       from '../../elements/loader/loader';
import Masonry      from 'react-responsive-masonry'

import React, { useState, useEffect, useRef }    from 'react';

export default (props) => {
     
    const [chains,              setChains]          = useState([]); // List of registered chains    
    const [is_admin,            setIsAdmin]      = useState(false);  
    const [show_memes_loader,   setShowMemesLoader] = useState(false);
    const [memes,               setMemes]           = useState([]); // list of memes to display
    const [modal_picture,       setModalPicture]    = useState(null); // show a picture as modal
    const [fade_out,            setFadeOut]         = useState(null);
    
    useEffect( () => {
               
        getChainsList();
        listMemes();
        
    }, []);
                             
    const getChainsList = async () => {
        
        const chains                = await Api.get("memes/chains");
        const chains_with_content   = await Api.get("memes/chains", {with_content:true});

        setChains(chains.content);       
    };
       
    const listMemes = async () => {

        setShowMemesLoader(true);

        const list = await Api.get("memes/pending");

        setMemes(list.content);

        setShowMemesLoader(false);       
    };        
    
    const showModalPicture = (e) => {
        
        setModalPicture(e.target.currentSrc);
    };
    
    const onSave = async (meme_id, selected_chain_id) => {
  
        //const save = await Api.post("memes/pending/confirm", {id:meme_id, chain:selected_chain_id}); 
        setFadeOut(meme_id);
        
        setTimeout(() => {
            
            const new_memes_list = memes.filter(meme => meme.id !== meme_id);

            setMemes([...new_memes_list]);
            
        }, 500);
    };
    
    const onRemove = async (meme_id, selected_chain_id) => {
        
        const save = await Api.post("memes/pending/remove", {id:meme_id});     
    };
    
    const onChangeChain = async (meme_id, chain_id) => {

        const res = [];
        
        for(let meme of memes){

            if(meme.id === meme_id){
                
                meme.chain = chain_id;
            }
            
            res.push(meme);
        }
            
        setMemes(res);
    };
    
    
    
    return (                 
        <div id="memes-container" class="pending-memes">                                    
                   
            {
                modal_picture ? <div id="modal-picture-mask" onClick={(e) => setModalPicture(null)}>
                                    <div id="picture-container"><img id="picture" src={modal_picture} /></div>
                                </div> : ""
            }                 
            {
                show_memes_loader ? 
                    <Loader /> 
                :                       
                    memes.map((meme, index) => {

                        return  <div class={fade_out === meme.id ? "fade-out white-container pending-meme" :  "white-container pending-meme"}>                                  
                                    <img class=" thumbnail" src={"data:image/png;base64,"+meme.base64} onClick={showModalPicture}/>                                    
                                    <PendingPanel 
                                    id          = {meme.id} 
                                    chain       = {meme.chain} 
                                    chains      = {chains} 
                                    onChangeChain = {onChangeChain}
                                    onSave      = {onSave} 
                                    onRemove    = {onRemove} />                                   
                                </div>
                    })      

            }                
                           
        </div>
    );
} 