// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("cursor.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.slider::-webkit-slider-thumb {
  appearance: none;
  width: 36px;
  height: 36px;
  border: 0;
  background: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  cursor: pointer;
}

.slider::-moz-range-thumb {
    
  width: 36px;
  height: 36px;
  border: 0;
  background: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  cursor: pointer;
}

.slidecontainer {
    
  width: 100%;
}


.slider { 
  appearance: none;
  width: 100%; 
  height: 7px; 
  background: var(--color-a);
  outline: none; 
  border-radius:3px;
}

`, "",{"version":3,"sources":["webpack://./src/elements/slider/style.css"],"names":[],"mappings":"AAAA;EAGE,gBAAgB;EAChB,WAAW;EACX,YAAY;EACZ,SAAS;EACT,mDAA6B;EAC7B,eAAe;AACjB;;AAEA;;EAEE,WAAW;EACX,YAAY;EACZ,SAAS;EACT,mDAA6B;EAC7B,eAAe;AACjB;;AAEA;;EAEE,WAAW;AACb;;;AAGA;EAGE,gBAAgB;EAChB,WAAW;EACX,WAAW;EACX,0BAA0B;EAC1B,aAAa;EACb,iBAAiB;AACnB","sourcesContent":[".slider::-webkit-slider-thumb {\n    \n  -webkit-appearance: none;\n  appearance: none;\n  width: 36px;\n  height: 36px;\n  border: 0;\n  background: url('cursor.png');\n  cursor: pointer;\n}\n\n.slider::-moz-range-thumb {\n    \n  width: 36px;\n  height: 36px;\n  border: 0;\n  background: url('cursor.png');\n  cursor: pointer;\n}\n\n.slidecontainer {\n    \n  width: 100%;\n}\n\n\n.slider {\n    \n  -webkit-appearance: none; \n  appearance: none;\n  width: 100%; \n  height: 7px; \n  background: var(--color-a);\n  outline: none; \n  border-radius:3px;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
