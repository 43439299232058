import './style.css';
import React, { useState, useEffect, useRef }   from 'react';
import {ReactComponent as AlgorandLogo}         from '../../icons/algorand-logo.svg';
import anime                                    from 'animejs/lib/anime.es.js';

export default (props) => {

    const [path, setPath] = useState(null);
    
    const xpath = useRef(null);
    const ball = useRef(null);
    
    useEffect( () => {
        
        const width = props.end - props.start;
        
        if(width){
            
            setPath("M0,"+width+" C180,0 350,0 400,"+width);
            
            console.log(path);
        }        
    });
    
    const run = () => {
        
       
        var path = anime.path(xpath.current);

        xpath.current = anime({
            
          targets: ball.current,
          translateX: path('x'),
          translateY: path('y'),
          rotate: path('angle'),
          easing: 'cubicBezier(0.560, 0.750, 0.595, 0.465)'   ,
          duration: 1000,
          loop: true
        });
    }
    
    return (
        
        <>
        <button onClick={run}>ok</button>
        {path ?
            <>
            <span style={{fontSize:0.5+"em"}} ref={ball}><AlgorandLogo class="icon" /></span>
            <div class="flying-rewards" style={{left:props.start+"px", width:271+"px"}}>            
                <svg viewBox="0 0 400 300">
                    <path ref={xpath} id="el" d={path} fill="none" stroke="black"/>
                </svg>
            </div>
            </>
            : ""
        }
        </>
    );
}
