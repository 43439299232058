// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ` #password-panel{
        
    display:flex;
    gap:15px;
}

.large-input{
    
    width:100%
}

.fa-eye, .fa-eye-slash{

    margin-top:27px;
    cursor:pointer;
}
`, "",{"version":3,"sources":["webpack://./src/elements/password/style.css"],"names":[],"mappings":"CAAC;;IAEG,YAAY;IACZ,QAAQ;AACZ;;AAEA;;IAEI;AACJ;;AAEA;;IAEI,eAAe;IACf,cAAc;AAClB","sourcesContent":[" #password-panel{\n        \n    display:flex;\n    gap:15px;\n}\n\n.large-input{\n    \n    width:100%\n}\n\n.fa-eye, .fa-eye-slash{\n\n    margin-top:27px;\n    cursor:pointer;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
