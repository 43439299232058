import './style.css';
import { passwordStrength } from 'check-password-strength'
import React, {useState, useEffect}    from 'react';

export default (props) => {
       
    const [show_clear_password, setShowClearPassword]   = useState(false);
    
    useEffect(() => {
    

    }, []);
    
    const getStrength = (password) => {
        
        const value = passwordStrength(props.password).value;        
        const colors = {
            
            "Too weak"  : "too-weak",
            "Weak"      : "weak",
            "Medium"    : "medium",
            "Strong"    : "strong"            
        };
        
        return colors[value] ?? "too-weak";
    };
    
    return (  
        <>
        <div id="password-panel">                
            <input 
                class       = "large-input" 
                type        = {show_clear_password ? "text" : "password"} 
                id          = "create-password" 
                onChange    = {e => props.onChange(e.target.value, getStrength(e.target.value), passwordStrength(props.password).value)} />                
            {
                show_clear_password ? 
                    <i class="fa fa-eye" onClick={e => setShowClearPassword(false)}></i> 
                : 
                    <i class="fa fa-eye-slash" onClick={e => setShowClearPassword(true)}></i> 
            }
        </div>
       
        </>
    );
}; 