import './style.css';
import ButtonFat from '../../elements/button-fat/button-fat';

export default (props) => {
   
    const onClickScheduleEvent = () => {
        
        window.location.pathname = "/events/schedule";
    }
    
    return (                
        <>
        <div id="schedule-event-button">
            <ButtonFat value="Schedule an event" onClick={onClickScheduleEvent} />
        </div>
        <div class="white-container">        
            <iframe 
                src         = "https://calendar.google.com/calendar/embed?height=600&wkst=1&ctz=America%2FNew_York&bgcolor=%23ffffff&title=AlgorandCulture%20scheduled%20events&showNav=0&showPrint=0&src=NDY0MDhmZWQyNjBmOGRkMTQzMzE3NWQ5Y2I2NmNkMjRlODlhMjUwNzAxMGU5YjgwMGRiY2RkNGU1N2Q5MjhiNkBncm91cC5jYWxlbmRhci5nb29nbGUuY29t&color=%23C0CA33" 
                width       = "100%" 
                height      = "600" 
                frameborder = "0" 
                scrolling   = "no">                
            </iframe>
        </div>  
        </>
    );
} 