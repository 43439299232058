// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#activity{
    
    width: 650px;
    margin: auto;
    background: rgb(24, 24, 24);
    font-size: 0.85em;
    padding:20px 20px 0px 20px;
    
    .entry{
        
       color: #bdbdbd;
       padding-bottom:20px;
     
    }
 
    .is-not-from-pool{
        
        color:#4e4e4e;
    }
    
    .rewards{
        
        gap:5px
    }

}

@keyframes fadeIn {

    0% { opacity: 0; }
    100% { opacity: 1; }
}`, "",{"version":3,"sources":["webpack://./src/components/activity/style.css"],"names":[],"mappings":"AAAA;;IAEI,YAAY;IACZ,YAAY;IACZ,2BAA2B;IAC3B,iBAAiB;IACjB,0BAA0B;;IAE1B;;OAEG,cAAc;OACd,mBAAmB;;IAEtB;;IAEA;;QAEI,aAAa;IACjB;;IAEA;;QAEI;IACJ;;AAEJ;;AAEA;;IAEI,KAAK,UAAU,EAAE;IACjB,OAAO,UAAU,EAAE;AACvB","sourcesContent":["#activity{\r\n    \r\n    width: 650px;\r\n    margin: auto;\r\n    background: rgb(24, 24, 24);\r\n    font-size: 0.85em;\r\n    padding:20px 20px 0px 20px;\r\n    \r\n    .entry{\r\n        \r\n       color: #bdbdbd;\r\n       padding-bottom:20px;\r\n     \r\n    }\r\n \r\n    .is-not-from-pool{\r\n        \r\n        color:#4e4e4e;\r\n    }\r\n    \r\n    .rewards{\r\n        \r\n        gap:5px\r\n    }\r\n\r\n}\r\n\r\n@keyframes fadeIn {\r\n\r\n    0% { opacity: 0; }\r\n    100% { opacity: 1; }\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
