
import './style.css';
import React, { useState, useEffect }   from 'react';
import Api                              from "../../includes/api";
import { useWallet}                    from '@txnlab/use-wallet'


export default (props) => {
            
    const [participation_key, setParticipationKey]      = useState(null);        
    const [selected_wallet, setSelectedWallet]          = useState(null);
    const {providers, activeAccount, signTransactions}  = useWallet();
   
    useEffect(()=>{
            
        if(activeAccount){
            
            authenticate();
        }
        
    }, [activeAccount]);
    
    const drawWallets = () => {

        const res = [];
        
        for(const key in providers){
            
           const wallet = providers[key];
           
            if(!wallet){
               
               continue;
            }
 
            res.push(
                <div class="wallet" onClick={(e)=> connectWallet(wallet)}>                    
                    <div class="icon"><img src={wallet.metadata.icon} height="50"/></div>
                    <div class="title">{wallet.metadata.name}</div>
                </div>
            );
        }
        
        return res;
    };
    
    const connectWallet = (wallet) => {
        
        wallet.connect(); //. This will trigger useEffect((), [activeAccount]);
    };
    
    const getProviderFromId = (id) => {
        
        const res = providers.filter( provider => provider.metadata.id === id );
        
        return res[0];
    };
    
    const authenticate = async () => {
              
        const token             = await Api.get("auth-token", {address:activeAccount.address});
        const current_provider  = getProviderFromId(activeAccount.providerId);
        const algosdk           = current_provider.algosdk;
        const algod             = current_provider.algodClient;
        const suggestedParams   = await algod.getTransactionParams().do();    
        const note              = new Uint8Array(Buffer.from(token.content));
        
        const transaction = algosdk.makePaymentTxnWithSuggestedParamsFromObject({
            from:   activeAccount.address,
            to:     activeAccount.address,           
            note:   note,
            suggestedParams
        });

        const encoded_transaction   = algosdk.encodeUnsignedTransaction(transaction)
        const signed_transaction    = await signTransactions([encoded_transaction]);
        const base64                = Buffer.from(signed_transaction[0]).toString("base64")        
        const authenticate          = await Api.post("auth-token", {txn:base64});
        
        localStorage.setItem("user", authenticate.content);
        
        props.onConnect();
    };
    
    const connectWith_ = async (trigger) => {
      /*
        const addresses = await algostack.client[trigger]();        
        
        if(addresses){
           
            const address   = addresses[0];
            const token     = await Api.get("auth-token", {address:address});
            
            if(token.error === false){

                const txn_prepare  = await algostack.txns.prepareTxn({

                    from:   address, 
                    to:     address, 
                    note:   new Uint8Array(Buffer.from(token.content))
                });

                const txn_unsigned = await algostack.txns.makeTxn(txn_prepare);        
                const txn_signed   = await algostack.txns.signTxns([txn_unsigned]); 
                const txn_base64   = Buffer.from(txn_signed[0]).toString('base64');        
                const connect      = await Api.post("auth-token", {txn:txn_base64});
                               
                localStorage.setItem("user", connect.content);
            }
        }
        
        <div id="connect-wallet">
            <p class="title">Select a wallet to connect with</p>
            <button class="small-btn" onClick={() => connectWith("connectPera")}>PeraWallet</button>
            <button class="small-btn"  onClick={() => connectWith("connectDefly")}>Defly</button>
            <button class="small-btn"  onClick={() => connectWith("connectDaffie")}>Daffie</button>
            <button class="small-btn"  onClick={() => connectWith("connectExodus")}>Exodus</button>        
        </div> 
             
         */
    };
    
    
    return <div id="connect-wallet">{drawWallets()}</div>
} 