import './style.css';
import Button   from '../../elements/button-fat/button-fat';
import Password from '../../elements/password/password';
import Api      from '../../includes/api';

import React, { useState, useEffect,useRef }    from 'react';

export default (props) => {
   
    const [email_address,    setEmailAddress]   = useState(null);
    const [password,        setPassword]        = useState({password:null, strength:null, description:null});  
   
 
    const onClickLogIn = async () => {
        
        const auth = await Api.post("auth", {email_address:email_address, password:password.password});
     
        if( !auth.error && auth.content ){
            
            localStorage.setItem("auth", auth.content);
            
            const back_to_path = (new URLSearchParams(window.location.search)).get("path");
            
            if(back_to_path){
                
                window.location = back_to_path;
            }
            else{
                
                window.navigation.reload();
            }
        }
    };

    const onChangePassword = (password, strength, description) => {
        
        setPassword({password:password, strength:strength, description:description});      
    };
    
    return (                 
        <div id="login-content">
            <div class="white-container">
            <label>
                Email address                
                <input class="large-input" type="text" onChange={e => setEmailAddress(e.target.value)} />
            </label>  
            <label>
                Password                             
                <Password password={password.password} onChange={onChangePassword}/>
            </label> 
            <Button value='Log in' onClick={onClickLogIn} />
            <div><a href='/forgotten-password'>I forgot my password</a></div>
            <div><a href='/create-account'>I don't have an account yet</a></div>
            </div>   
        </div>                    
    );
} 