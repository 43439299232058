import './style.css';
import React, { useState, useEffect,useRef }    from 'react';
import { ReactComponent as AlgorandSymbol}      from '../../icons/algorand-logo.svg';
import Api                                      from "../../includes/api";
import AlgoAmount                               from "../../elements/algo-amount/algo-amount";
import Toggle                                   from "../../elements/toggle/toggle";
import Loader                                   from "../../elements/loaders/layers";
import Socket                                   from "../../includes/ws";
import FadeIn                                   from 'react-fade-in';
import algosdk, {Transaction}                   from "algosdk";
        
 
export default (props) => {
        
    const [loading, setLoading]     = useState(false); 
    const [activity, setActivity]   = useState([]); 
    const [user, setUser]           = useState(null);
    const [waiting, setWaiting]    = useState(true);
    
    useEffect(() => {
        
        listenWs();
        
    }, []);
  
 
    // Load user's stake from chain
    const loadActivity = async () => {               
                           
        setLoading(true);

        const get_activity = await Api.get("activity");

        setLoading(false);

        setActivity(get_activity.content.activity);                
    };
    
    const listenWs = async () => {
        
        const user  = await Api.get("user");
        
        if(user.content && user.content?.address){
            
            setUser(user.content);
        }        
       
        Socket.listen().onAny((event, ...args) => {
     
            console.log(event, args);
            
            if(event === user.content.address){
               
            }   
            
            setWaiting(false);
            
            activity.unshift({event:event, args:args[0]});

            setActivity([...activity.slice(0,100)]);

        });                               
    };           
    
    
    const drawEntry = (index, event_type, args) => {
        
        let res = null;
        
        switch(event_type){
            
            case "block": res = drawBlockEntry(index, args); break;                
        }
        
        return res;
    };

    
    
    const drawBlockEntry = (index, args) => {

        let is_from_pool = <div class="is-not-from-pool">The proposer is not a Milkshake pool</div>;
        let global_stake = null;
        let is_valid_pool = false;
        
        if(args.is_from_pool === "rewards"){
            
            is_from_pool = <div class="success">The proposer is a reward pool</div>    
            global_stake = "Rewards pool";
            is_valid_pool = true;
        }
        else if(args.is_from_pool === true){
            
            is_from_pool = <div class="success">The proposer is a staking pool</div>
            global_stake = "Staking pool";
            is_valid_pool = true;
        }
        
        return  <>                 
                    <div class="flex rewards"><div>Block #{args.round}</div><div>{is_from_pool}</div></div>
                    <div>Proposer: {args.proposer}</div>
                    <div class="flex rewards"><div>Rewards:</div><div><AlgoAmount value={args.rewards} icon-width="12px" /></div></div>  
                    { 
                        is_valid_pool ?
                        <>
                        <div class="flex rewards"><div>Distributed:</div><div>{args.distributed} microalgos</div></div>
                        <div class="flex rewards"><div>{global_stake}:</div><div>{args.app_stake}</div></div>
                        </>
                        : ""
                    }
                </>
                
    };
    
    return (
        <div id="activity">
        {
            waiting ? <div>Waiting next block ...</div> : ""
        }
        {
            activity.map((entry, index) => {
                                
               return   <div class="entry">
                        {drawEntry(index, entry.event, entry.args)}
                        </div>
            })
        }
        {loading ? <Loader /> : ""}
        </div>
    );
}
