import './style.css';
 
export default (props) => {
   
    return (
            
        <>
        <h1>The pools</h1>
        <div>There are two pools: the main pool and the rewards pool.</div>
<div>The main pool is where your staked Algos lie.</div>
<div>The rewards pool is where your rewards go each time the main pool wins a block.</div>
<div>Your rewards are frozen in the rewards pool for 30 days.</div>
<div>This rewards-pool is also participating in the consensus. In other words, your rewards are put to work to generate more rewards.</div>
<div>After 30 days, you are free to claim your rewards or let them stay in the rewards pool to continue earning more rewards.</div>
<div>The rewards earned by the main pool are distributed based on your stake of Algos, while the rewards earned from the rewards pool are distributed based on your unclaimed rewards</div>

        
        
        <h1>LUDO Tokenomics</h1>
    
    <h2>Token Distribution</h2>
    <ul>
        <li><strong>Total Minted Tokens:</strong> 10 million $LUDO</li>
        <li><strong>Pre-Sale:</strong> 5 million $LUDO</li>
        <ul>
            <li><strong>Price:</strong> 1 $LUDO = 1 $ALGO</li>
            <li><strong>Funds Raised:</strong> 5 million $ALGO</li>
        </ul>
    </ul>

    <h2>Liquidity Pool</h2>
    <ul>
        <li><strong>Tokens Added:</strong> 5 million $LUDO</li>
        <li><strong>$ALGO Added:</strong> 5 million $ALGO (from pre-sale funds)</li>
    </ul>

    <h2>Usage of Rewards</h2>
    <ul>
        <li>The service you back earns rewards in $ALGO.</li>
        <li>These $ALGO rewards are used to buy back $LUDO tokens from the liquidity pool.</li>
        <li>The $LUDO tokens bought back are then burned.</li>
    </ul>

    <h2>Key Implications and Considerations</h2>
    <h3>Deflationary Mechanism</h3>
    <ul>
        <li><strong>Decreasing Supply:</strong> Burning $LUDO tokens reduces the total supply, which can increase the scarcity of the token.</li>
        <li><strong>Potential Price Appreciation:</strong> As the supply decreases and if the demand remains steady or increases, the price of $LUDO is likely to rise.</li>
    </ul>

    <h3>Liquidity Pool Dynamics</h3>
    <ul>
        <li>The initial addition of equal parts $LUDO and $ALGO ensures a balanced liquidity pool.</li>
        <li>Continuous buybacks and burns will decrease the circulating supply of $LUDO, which can positively affect the token’s price.</li>
    </ul>

    <h3>Enhanced Investor Incentives</h3>
    <ul>
        <li><strong>Holding Incentives:</strong> Investors may be more likely to hold $LUDO tokens, anticipating a price increase due to the deflationary mechanism.</li>
        <li><strong>Market Confidence:</strong> Regular burns provide a transparent and straightforward way to demonstrate the ongoing value support for $LUDO.</li>
    </ul>

    <h3>Impact of Market Volatility</h3>
    <ul>
        <li><strong>$ALGO Price Fluctuations:</strong> The value of $ALGO can affect the liquidity pool balance and the buyback capacity.</li>
        <li><strong>Reward Stability:</strong> The burn rate and the effectiveness of the deflationary mechanism depend on consistent reward generation in $ALGO.</li>
    </ul>

    <h2>Summary</h2>
    <p>Your tokenomics model with the added burn mechanism creates a robust and deflationary ecosystem for $LUDO. The buybacks using $ALGO rewards, followed by burning the $LUDO tokens, reduce the circulating supply, potentially driving up the token's value over time.</p>

    <h2>Additional Considerations</h2>
    <ul>
        <li><strong>Transparency and Communication:</strong> Keep the community informed about the buyback and burn activities to maintain trust and confidence.</li>
        <li><strong>Reward Optimization:</strong> Maximize the rewards earned by the service to ensure a steady stream of $ALGO for buybacks and burns.</li>
        <li><strong>Reserve Management:</strong> Consider setting aside a portion of $ALGO rewards to manage periods of low reward generation or market downturns, ensuring continuous buyback and burn operations.</li>
    </ul>
        </> 
    );
}