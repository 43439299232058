import './style.css';

import React, { useState, useEffect }    from 'react';
export default (props) => {
   
    const [price, setPrice] = useState(0);
    
    useEffect(() => {
    
        getPrice();
        
    }, []);
    
    const getPrice = async () => {
        
        const algo_usdc_rate = await (await fetch("https://api.binance.com/api/v3/ticker/price?symbol=ALGOUSDC")).json();
        
        setPrice(parseFloat(algo_usdc_rate.price).toFixed(4));
        
        setTimeout(getPrice, 15000);
    };
    
    return (                 
        <div id="algo-pricing">
            <img src="/pics/algorand_logo_mark_white.svg"/>
            <div id="price">= {price}</div>
        </div>
    );
} 