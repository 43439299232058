// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `

.user-block{
       
    max-width:520px;    
    margin:auto;
    margin-bottom:50px;  
    
    .big{
        
        margin-top:10px;
        margin-bottom:10px;
    }
    
    .stake-percent{
        
        text-align:center;
    }
    
    .user-panel{
    
        justify-content:right;
        margin-top: 10px;
        margin-bottom:10px;
        gap:0px;
        
        .action, .panel-icon-purple{
    
            color:var(--color-a);
            border-radius:3px;
            cursor:pointer;
            transition:background 250ms;
            margin-left:10px;
            padding:0px 3px;
            font-size:0.9em
        }

        .action:hover, .action-selected{

            background-color: #fdcdde;
        }     

        .action-purple{
            
            color:var(--color-b)
        }
        
        .action-purple:hover, .action-purple-selected{

            background-color:#e1d2d7
        }
        
    }      
}

`, "",{"version":3,"sources":["webpack://./src/components/user-earnings/style.css"],"names":[],"mappings":";;AAEA;;IAEI,eAAe;IACf,WAAW;IACX,kBAAkB;;IAElB;;QAEI,eAAe;QACf,kBAAkB;IACtB;;IAEA;;QAEI,iBAAiB;IACrB;;IAEA;;QAEI,qBAAqB;QACrB,gBAAgB;QAChB,kBAAkB;QAClB,OAAO;;QAEP;;YAEI,oBAAoB;YACpB,iBAAiB;YACjB,cAAc;YACd,2BAA2B;YAC3B,gBAAgB;YAChB,eAAe;YACf;QACJ;;QAEA;;YAEI,yBAAyB;QAC7B;;QAEA;;YAEI;QACJ;;QAEA;;YAEI;QACJ;;IAEJ;AACJ","sourcesContent":["\r\n\r\n.user-block{\r\n       \r\n    max-width:520px;    \r\n    margin:auto;\r\n    margin-bottom:50px;  \r\n    \r\n    .big{\r\n        \r\n        margin-top:10px;\r\n        margin-bottom:10px;\r\n    }\r\n    \r\n    .stake-percent{\r\n        \r\n        text-align:center;\r\n    }\r\n    \r\n    .user-panel{\r\n    \r\n        justify-content:right;\r\n        margin-top: 10px;\r\n        margin-bottom:10px;\r\n        gap:0px;\r\n        \r\n        .action, .panel-icon-purple{\r\n    \r\n            color:var(--color-a);\r\n            border-radius:3px;\r\n            cursor:pointer;\r\n            transition:background 250ms;\r\n            margin-left:10px;\r\n            padding:0px 3px;\r\n            font-size:0.9em\r\n        }\r\n\r\n        .action:hover, .action-selected{\r\n\r\n            background-color: #fdcdde;\r\n        }     \r\n\r\n        .action-purple{\r\n            \r\n            color:var(--color-b)\r\n        }\r\n        \r\n        .action-purple:hover, .action-purple-selected{\r\n\r\n            background-color:#e1d2d7\r\n        }\r\n        \r\n    }      \r\n}\r\n\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
