import './style.css';
import Toggle       from '../../elements/toggle/toggle';
import ButtonFat    from '../../elements/button-fat/button-fat';
import Api          from '../../includes/api';
import Masonry   from 'react-responsive-masonry'

import React, { useState, useEffect,useRef }    from 'react';

export default (props) => {
    
    const [product,                 setProduct]             = useState({});    
    const [preview_picture,         setPreviewPicture]      = useState(null);
    const [invalid_picture_size,    setInvalidPictureSize]  = useState(false);
    
    const input_file_ref = useRef(0);
    
    useEffect( () => {              
        
    }, []);
 
     
    const isAdmin = async () => {
        
        const is_logged_in = await Api.get("user");
        
        if( !is_logged_in.error && is_logged_in.content){
            
           
        }
        else{
         
            
        }
    };  
    
    const onSelectFileToUpload = async (e) => {
                    
        const file      = input_file_ref.current.files[0];//e.target.files[0];  
        const reader    = new FileReader();                       
        
        if(file){
            
            setInvalidPictureSize(false);
            
            reader.readAsDataURL(file);

            reader.onload = (e) => {
                
                const img   = new Image(); 
                img.src     = e.srcElement.result;
                img.onload  = () => {
                    
                    if(img.width !== 2500 || img.height !== 250){
                        
                        setInvalidPictureSize(true);
                    }
                };
                               
                setPreviewPicture(e.srcElement.result); 
                saveProductProperty("picture", e.srcElement.result)
            };                
        }
    };
    
    const saveProductProperty = (property, value) => {
        
        product[property] = value;
        
        setProduct({...product});
    };
    
    const save = async () => {
        
        const save = Api.post("store/new-product", {product:product});
        
        if( !save.error && save.content > 0 ){
            
            
        }
    };
    
    return (                 
        <div id="store-add-container">
            <div class="white-container">
                <label>Product title<input type="text" id="product-title" onChange={(e) => saveProductProperty("title", e.target.value)} /></label>    
                <label>Price in $<input type="number" id="product-price" onChange={(e) => saveProductProperty("price", e.target.value)} /></label>  
                <label>Quantity<input type="number" id="product-quantity" onChange={(e) => saveProductProperty("quantity", e.target.value)}/></label>


                <label>
                    Picture 200x250
                    <div id="picture-form">
                        {
                            preview_picture ?                         
                            <div id="picture-preview">
                                <div><img src={preview_picture} id="preview-article-picture" width="250" height="200"/></div>
                                {invalid_picture_size ? <div class="alert"><i class="fa fa-exclamation"></i> Wrong picture size</div> : ""}
                            </div>
                            : 
                            ""
                        }
                        <div><ButtonFat value="Select a picture" onClick={(e) => input_file_ref.current.click()}/></div>
                        <input 
                            type        = "file" 
                            ref         = {input_file_ref} 
                            id          = "article-picture-file"
                            accept      = "image/*" 
                            ref         = {input_file_ref} 
                            onChange    = {onSelectFileToUpload}
                        />
                    </div>
                </label>
                <div class="button-footer"><ButtonFat value="Save new product" onClick={save}/></div>
            </div>
        </div>
    );
} 