import axios from "axios";

const host = "https://api.twolate.com/";

let authentication_token    = null;
let address                 = null;

const request = async (config = {}, url = "", params = null) => {
    
    if(!config.hasOwnProperty("headers")){
        
        config.headers = {};
    }   
    
    let res         = null;
    let connection  = {
        
        method:     config.method,
        url:        host+url,
        data:       params,
        headers:    config.headers,        
    };
   
    // Axios will ignore the property "data" if the method is GET. 
    // The params must be put into the property "params" so they can be 
    // encoded and added to the URL.
    if(connection.method === "get"){
        
        connection.params = params;
    }  
    
    try{
       
        const exec  = await axios(connection);         
        res         = exec?.data ?? null;        
    }        
    catch(e){
                
        res  = e?.response?.data ?? null;        
    }  
    
    return res;
};

const getHeaders = (method = "get") => {
    
    let res             = {method:method, headers:{}};
    const auth_token    = localStorage.getItem("user");
    
    if(auth_token){
        
        res.headers.Authorization = `Bearer ${auth_token}`;
    }
    
    return res;
};

const api = {
 
    get: async (endpoint = "", params = {}) => {

        return request(getHeaders("get"), endpoint, params);
    },
    
    post: async (endpoint = "", params = {}) => {

        return request(getHeaders("post"), endpoint, params);
    },
    
    setUser: async(token) => {
        
        authentication_token = token;                
    },
};

export default api;