// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#payment-panel{

    margin-top:30px;
    
    #stripe-logo{
        
        margin-top:20px;
    }
    
    #algorand-wallets{
        
        display:flex;
        gap:25px;
        
        #wallet{
        
            padding:5px;
            cursor:pointer;
            opacity:0.7;
            transition:opacity 200ms;
            
            #icon{
                
                text-align:center
            }
            
            #title{
                
                text-align:center
            }
        }
        
        #wallet:hover{
                   
            opacity:1;
        }
    }
}




`, "",{"version":3,"sources":["webpack://./src/components/payment-panel/style.css"],"names":[],"mappings":"AAAA;;IAEI,eAAe;;IAEf;;QAEI,eAAe;IACnB;;IAEA;;QAEI,YAAY;QACZ,QAAQ;;QAER;;YAEI,WAAW;YACX,cAAc;YACd,WAAW;YACX,wBAAwB;;YAExB;;gBAEI;YACJ;;YAEA;;gBAEI;YACJ;QACJ;;QAEA;;YAEI,SAAS;QACb;IACJ;AACJ","sourcesContent":["#payment-panel{\r\n\r\n    margin-top:30px;\r\n    \r\n    #stripe-logo{\r\n        \r\n        margin-top:20px;\r\n    }\r\n    \r\n    #algorand-wallets{\r\n        \r\n        display:flex;\r\n        gap:25px;\r\n        \r\n        #wallet{\r\n        \r\n            padding:5px;\r\n            cursor:pointer;\r\n            opacity:0.7;\r\n            transition:opacity 200ms;\r\n            \r\n            #icon{\r\n                \r\n                text-align:center\r\n            }\r\n            \r\n            #title{\r\n                \r\n                text-align:center\r\n            }\r\n        }\r\n        \r\n        #wallet:hover{\r\n                   \r\n            opacity:1;\r\n        }\r\n    }\r\n}\r\n\r\n\r\n\r\n\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
