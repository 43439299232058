import './style.css';
import React, { useState, useEffect,useRef }    from 'react';

export default (props) => {
        
    const [slide_value, setSlideValue] = useState(0);
    
    const slide = (e) => {
        
        setSlideValue(e.target.value);
        props.onChange(e.target.value);
    };

     useEffect(() => {
    
        if(props.value){

            setSlideValue(props.value);
        }
        
    }, []);
    
    
    return (
          
        <>
       
        <div class="slidecontainer">
            <input 
                type        = "range" 
                min         = "0" 
                max         = {props.max}
                value       = {slide_value} 
                class       = "slider" 
                onChange    = {slide} />
        </div>
                          
       </>
    );
}