import Socials          from "../socials/socials";
import NavigationLinks  from "../navigation-links/navigation-links";
import AlgoPricing      from '../../elements/algo-pricing/algo-pricing';
import React, { useState, useEffect }    from 'react';

import './style.css';

export default (props) => {
   
    const [price, setPrice] = useState(0);
    
    useEffect(() => {
    
        getPrice();
        
    }, []);
    
    const getPrice = async () => {
        
        const algo_usdc_rate = await (await fetch("https://api.binance.com/api/v3/ticker/price?symbol=ALGOUSDC")).json();
        
        setPrice(parseFloat(algo_usdc_rate.price).toFixed(4));
        
        setTimeout(getPrice, 15000);
    };

    return (  
            <>
        <div id="top-bar">            
            <div id="logo">
                <span id="logo-text-1">Algorand</span>
                <span id="logo-text-2">Culture</span>            
            </div>
            <div id="socials">
                <Socials />
            </div>

            <div class="flex-break"></div>
            <nav>                
                <NavigationLinks />
            </nav>
            <div id="algo-pricing">
                <img src="/pics/algorand_logo_mark_white.svg"/>
                <div id="price">= {price}$</div>
            </div>
           
        </div>  
        
            </>
    );
} 