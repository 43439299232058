// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#menu{
    
    display: flex;
    gap: 30px;   
    justify-content: center;
    margin-top: 60px;
    
    a{
        
        color: var(--color-a);
        text-decoration: none;
        text-transform: uppercase;
        transition: color 200ms
    }
    
    a:hover{
        
        color: var(--color-b);
    }
    
    .selected{
        
        color: var(--color-b);
    }
}`, "",{"version":3,"sources":["webpack://./src/components/menu/style.css"],"names":[],"mappings":"AAAA;;IAEI,aAAa;IACb,SAAS;IACT,uBAAuB;IACvB,gBAAgB;;IAEhB;;QAEI,qBAAqB;QACrB,qBAAqB;QACrB,yBAAyB;QACzB;IACJ;;IAEA;;QAEI,qBAAqB;IACzB;;IAEA;;QAEI,qBAAqB;IACzB;AACJ","sourcesContent":["#menu{\r\n    \r\n    display: flex;\r\n    gap: 30px;   \r\n    justify-content: center;\r\n    margin-top: 60px;\r\n    \r\n    a{\r\n        \r\n        color: var(--color-a);\r\n        text-decoration: none;\r\n        text-transform: uppercase;\r\n        transition: color 200ms\r\n    }\r\n    \r\n    a:hover{\r\n        \r\n        color: var(--color-b);\r\n    }\r\n    \r\n    .selected{\r\n        \r\n        color: var(--color-b);\r\n    }\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
