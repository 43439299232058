import './style.css';
import Api from '../../includes/api';

import React, { useState, useEffect,useRef }    from 'react';

export default (props) => {
   
    const [show_floating_menu,  setShowFloatingMenu]    = useState(false);
    const [is_mouse_in,         setIsMouseIn]           = useState(false);     
    const [pathname,            setPathname]            = useState(null);
    const [is_logged_in,        setIsLoggedIn]          = useState(null);
    
    useEffect(() => {
        
        setPathname(window.location.pathname);
                       
    }, []);
           
    
    const getPathname = (pathname) => {
             
        const root_path = window.location.pathname.split("/")[1];
        const sub_path  = window.location.pathname.split("/")[2];
             
        return !sub_path ? "profile" : sub_path;
    };
    
  
        

    return (                 
        <nav id="user-navigation-links">
            <ul>
                <li><a href="/profile" class={getPathname() === "profile" ? "selected" : ""}>Profile</a> </li>
                <li><a href="/profile/events" class={getPathname() === "events" ? "selected" : ""}>Events</a> </li>
                <li><a href="/profile/orders" class={getPathname() === "orders" ? "selected" : ""}>Orders</a> </li>
            </ul>
        </nav>                      
    );
} 