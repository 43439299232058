// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#pools-admin{

    #move_liquidity_amount{    

        width:100px
    }

    select{

        padding:8px;
       border: 1px solid var(--border-input);
    }

    table{

        margin:auto;
    }

    th{

        min-width:150px;
        background-color: #f76a9b;
        color: white;
        font-weight: normal;
        padding: 10px;
    }


    td {

        height:15px;
        text-align: center;
    }

    .td-with-button{

        justify-content: end;
    }

    .btns-panel{

        border-radius:0px 3px 3px 3px;
        padding: 20px;
        margin:  0px 0px 20px 0px;
        text-align: left;
        border: 1px solid #cbcbcb;    
    }

    .btns-panel-title{

        text-align: left;
        margin-top: 10px;
        margin-bottom: 0px;
        width: fit-content;
        background: #e3e3e3;
        padding: 5px;
        border-radius:3px 3px 0px 0px;
    }

    .separator{

        height:25px;
    }
}`, "",{"version":3,"sources":["webpack://./src/components/admin/style.css"],"names":[],"mappings":"AAAA;;IAEI;;QAEI;IACJ;;IAEA;;QAEI,WAAW;OACZ,qCAAqC;IACxC;;IAEA;;QAEI,WAAW;IACf;;IAEA;;QAEI,eAAe;QACf,yBAAyB;QACzB,YAAY;QACZ,mBAAmB;QACnB,aAAa;IACjB;;;IAGA;;QAEI,WAAW;QACX,kBAAkB;IACtB;;IAEA;;QAEI,oBAAoB;IACxB;;IAEA;;QAEI,6BAA6B;QAC7B,aAAa;QACb,yBAAyB;QACzB,gBAAgB;QAChB,yBAAyB;IAC7B;;IAEA;;QAEI,gBAAgB;QAChB,gBAAgB;QAChB,kBAAkB;QAClB,kBAAkB;QAClB,mBAAmB;QACnB,YAAY;QACZ,6BAA6B;IACjC;;IAEA;;QAEI,WAAW;IACf;AACJ","sourcesContent":["#pools-admin{\n\n    #move_liquidity_amount{    \n\n        width:100px\n    }\n\n    select{\n\n        padding:8px;\n       border: 1px solid var(--border-input);\n    }\n\n    table{\n\n        margin:auto;\n    }\n\n    th{\n\n        min-width:150px;\n        background-color: #f76a9b;\n        color: white;\n        font-weight: normal;\n        padding: 10px;\n    }\n\n\n    td {\n\n        height:15px;\n        text-align: center;\n    }\n\n    .td-with-button{\n\n        justify-content: end;\n    }\n\n    .btns-panel{\n\n        border-radius:0px 3px 3px 3px;\n        padding: 20px;\n        margin:  0px 0px 20px 0px;\n        text-align: left;\n        border: 1px solid #cbcbcb;    \n    }\n\n    .btns-panel-title{\n\n        text-align: left;\n        margin-top: 10px;\n        margin-bottom: 0px;\n        width: fit-content;\n        background: #e3e3e3;\n        padding: 5px;\n        border-radius:3px 3px 0px 0px;\n    }\n\n    .separator{\n\n        height:25px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
