// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#connect-wallet{
    
    display: flex;
    justify-content: center;
    gap: 40px;
    
    .wallet{
        
        width:50px;
        cursor:pointer;
        
        .icon{
            
            img{
                
                border-radius:50%;
            }            
        }
    }
}`, "",{"version":3,"sources":["webpack://./src/components/connect-wallet/style.css"],"names":[],"mappings":"AAAA;;IAEI,aAAa;IACb,uBAAuB;IACvB,SAAS;;IAET;;QAEI,UAAU;QACV,cAAc;;QAEd;;YAEI;;gBAEI,iBAAiB;YACrB;QACJ;IACJ;AACJ","sourcesContent":["#connect-wallet{\r\n    \r\n    display: flex;\r\n    justify-content: center;\r\n    gap: 40px;\r\n    \r\n    .wallet{\r\n        \r\n        width:50px;\r\n        cursor:pointer;\r\n        \r\n        .icon{\r\n            \r\n            img{\r\n                \r\n                border-radius:50%;\r\n            }            \r\n        }\r\n    }\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
