// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.socials-btns{
    
   
    
    a{
        color:white;
        margin-left:20px;
    }
}`, "",{"version":3,"sources":["webpack://./src/components/socials/style.css"],"names":[],"mappings":"AAAA;;;;IAII;QACI,WAAW;QACX,gBAAgB;IACpB;AACJ","sourcesContent":[".socials-btns{\r\n    \r\n   \r\n    \r\n    a{\r\n        color:white;\r\n        margin-left:20px;\r\n    }\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
