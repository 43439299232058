import './style.css';
import React, { useState, useEffect,useRef }    from 'react';
import { ReactComponent as AlgorandSymbol}      from '../../icons/algorand-logo.svg';
import Api                                      from "../../includes/api";
import AlgoAmount                               from "../../elements/algo-amount/algo-amount";
import Slider                                   from "../../elements/slider/slider";
import algosdk                                  from "algosdk";
 
export default (props) => {
      
    const [left_stake_value, setLeftStakeValue]   = useState(0);
    
    useEffect(() => {    
               
        setLeftStakeValue(props["user-stake"]);
       
    }, []);
    
    return (
        <>

        <div class="white-block" id="remove-stake">
            <div class="title">Decrease your current stake</div>
            
            <table class="table-structure">
            <tr>
                <td>New stake:</td>
                <td class="td-amount"><AlgoAmount value={left_stake_value}  icon-width="1em" /></td>
            </tr>
            <tr>
                <td>You will receive:</td>
                <td class="td-amount"><AlgoAmount value={props["user-stake"] - left_stake_value} icon-width="1em" /></td>
            </tr>
           
            </table>
                        
            <Slider 
                max         = {props["user-stake"]} 
                value       = {props["user-stake"]} 
                onChange    = {(value) => setLeftStakeValue(value)}/>
            <div class="flex" id="buttons-panel">
                <button class="small-btn" onClick={e => props.onUnstake(props["user-stake"] - left_stake_value)}>Proceed</button>
                <button class="small-btn" onClick={props.onClose}>Cancel</button>
            </div>
        </div>    
                    
       
        </>
    );
}

