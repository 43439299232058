// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
#login-content, #create-account-content, #reset-password-content{
    
    width:400px;
    margin:auto;
    
    input{
        
         margin-top:15px;
         margin-bottom:30px;
    }
}

#create-account-content, #reset-password-content{
          
   
    
    #create-password-strength{
        
        margin-bottom:25px;
    }
    
   
    
    .too-weak{ color:red }
    .weak{color:#ff781b}
    .medium{color:var(-main--green)}
    .strong{color:var(-main--green)}
    
    .error{margin-top:15px}
}

#reset-password-content{
    
    width:450px;   
}

.user-event{
    
    margin-bottom:20px;
}

.user-order{
    
    margin-bottom:20px;
    
    .title{
        
        margin-top:20px;
        margin-bottom:10px;
    }
    
    td{
        
        padding: 10px 20px;
        background: var(--light-gray);
    }
    
    .shipping-address{
        
        padding:10px;
        background: var(--light-gray);
        width:-moz-fit-content;
        width:fit-content;
        line-height:21px;
    }        
}`, "",{"version":3,"sources":["webpack://./src/components/profile/style.css"],"names":[],"mappings":";AACA;;IAEI,WAAW;IACX,WAAW;;IAEX;;SAEK,eAAe;SACf,kBAAkB;IACvB;AACJ;;AAEA;;;;IAII;;QAEI,kBAAkB;IACtB;;;;IAIA,WAAW,UAAU;IACrB,MAAM,aAAa;IACnB,QAAQ,uBAAuB;IAC/B,QAAQ,uBAAuB;;IAE/B,OAAO,eAAe;AAC1B;;AAEA;;IAEI,WAAW;AACf;;AAEA;;IAEI,kBAAkB;AACtB;;AAEA;;IAEI,kBAAkB;;IAElB;;QAEI,eAAe;QACf,kBAAkB;IACtB;;IAEA;;QAEI,kBAAkB;QAClB,6BAA6B;IACjC;;IAEA;;QAEI,YAAY;QACZ,6BAA6B;QAC7B,sBAAiB;QAAjB,iBAAiB;QACjB,gBAAgB;IACpB;AACJ","sourcesContent":["\r\n#login-content, #create-account-content, #reset-password-content{\r\n    \r\n    width:400px;\r\n    margin:auto;\r\n    \r\n    input{\r\n        \r\n         margin-top:15px;\r\n         margin-bottom:30px;\r\n    }\r\n}\r\n\r\n#create-account-content, #reset-password-content{\r\n          \r\n   \r\n    \r\n    #create-password-strength{\r\n        \r\n        margin-bottom:25px;\r\n    }\r\n    \r\n   \r\n    \r\n    .too-weak{ color:red }\r\n    .weak{color:#ff781b}\r\n    .medium{color:var(-main--green)}\r\n    .strong{color:var(-main--green)}\r\n    \r\n    .error{margin-top:15px}\r\n}\r\n\r\n#reset-password-content{\r\n    \r\n    width:450px;   \r\n}\r\n\r\n.user-event{\r\n    \r\n    margin-bottom:20px;\r\n}\r\n\r\n.user-order{\r\n    \r\n    margin-bottom:20px;\r\n    \r\n    .title{\r\n        \r\n        margin-top:20px;\r\n        margin-bottom:10px;\r\n    }\r\n    \r\n    td{\r\n        \r\n        padding: 10px 20px;\r\n        background: var(--light-gray);\r\n    }\r\n    \r\n    .shipping-address{\r\n        \r\n        padding:10px;\r\n        background: var(--light-gray);\r\n        width:fit-content;\r\n        line-height:21px;\r\n    }        \r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
