import './style.css';
import Button   from '../../elements/button-fat/button-fat';
import Api      from '../../includes/api';
import User     from './user';
import Auth     from './auth';
import UserNav  from '../user-navigation-links/user-navigation-links';
import React, { useState, useEffect }    from 'react';

export default (props) => {
   
    const [is_logged_in,    setIsLoggedIn]  = useState(null);
    const [orders,          setOrders]      = useState([]);
    const [show_payment,    setShowPayment] = useState(null);
    
    useEffect( () => {

        isLoggedIn();   
        getUserOrders();
        
    }, []);
    
    const isLoggedIn = async () => {
        
        const is_logged_in = await Api.get("user");
        
        if( !is_logged_in.error && is_logged_in.content){
            
            setIsLoggedIn(true);
        }
        else{
            
            setIsLoggedIn(false);
        }
    };
    
    const getUserOrders = async () => {
        
        const res       = [];
        const orders    = await Api.get("user/orders");
      
        for(let order of orders.content){
            
            //order.data = JSON.parse(event.event);
                
            res.push(order);
        }
      
        setOrders(res);
    };       
 
    const formatDate = (dateTime) => {
        
        const date = new Date(parseInt(dateTime));
       
        return date.toLocaleDateString()+" "+ date.toLocaleTimeString();
    };
    
    const showPayment = async (e, order_id) => {
        
        e.preventDefault();
        
        const payment = await Api.get("user/store/payment", {order_id:order_id});
        
        setShowPayment({id:order_id, payment:payment.content});
    };
    
    const hidePayment = async (e) => {
        
        e.preventDefault();                
        
        setShowPayment(null);
    };
    
    const drawPayment = () => {
        
        const res       = [];
        const method    = show_payment.payment.method;
        
        res.push(<div><a href='#' class="selected" onClick={(e) => hidePayment(e)}>Hide payment</a></div>)
        
        if(method === "algorand"){
            
            res.push(
                <>
                <p>Method: Algorand</p>
                <div>Transaction: <a target="_blank" href={"https://allo.info/tx/"+show_payment.payment.proof} alt="View transaction on Allo">{show_payment.payment.proof}</a></div>
                </>
            );            
        }
        else if(method === "stripe"){
            res.push(
                <>
                <p>Method: Stripe</p>
                <div>Payment id: {show_payment.payment.proof}</div>
                </>
            );          
        }
        
        return res;
    };
    
    const drawStatus = (order) => {
    
        /* Status: {order.status === null ? "The order is waiting to be processed" : ""} */
        
        const res = [];
        
        if( !order.processing_ts ){
            
            res.push(<>Status: The order is waiting to be processed</>)
        }
        
        if( order.processing_ts ){
            
            res.push(
                <>
                <div class="title">Status:</div>
                <div>{formatDate(order.processing_ts)} - The order is being processed.</div>
                {order.processed_ts ? <div>{formatDate(order.processed_ts)} - Your order was sent. Delivery number: {order.delivery_number}</div> : ""}
                </>
             )
        }

        return res;
    };
    
    const drawBasket = (basket) => {
    
        const res = [];
        let total = 0;
        
        for(const product of basket){
            
            const price = product.quantity * product.price;
            total       += price;
            
            res.push(
                <tr>
                    <td>{product.quantity} x {product.title}</td>
                    <td class="td-price">{price}$</td>
                </tr>
            )
        }
        
        res.push(<tr><td colspan="2">Total: <b>{total}$</b></td></tr>);
        
        return <table class="order-table">{res}</table>;
    }
    
    const drawAddress = (address) => {
    
        const res = [];
        
        res.push(
            <>    
            <div>{address.firstname} {address.lastname}</div>
            <div>{address.street}</div>
            <div>{address.zipcode} {address.city}</div>
            <div>{address.country}</div>
            </>
        );

        return res;
    }
    
    return ( 
        <>
        <UserNav />
        {
        orders.map(order => {
        
            return  <div class="white-container user-order">
                        <date><i class="fa fa-clock"></i> {formatDate(order.ts)}</date>
                        <p>
                            {drawStatus(order)}
                        </p>
                        <p>
                            <div class="title">Order:</div>
                            {drawBasket(JSON.parse(order.basket))}
                        </p>
                        <p>
                            <div class="title">Shipping address:</div>
                            <div class="shipping-address">
                            {drawAddress(JSON.parse(order.address))}
                            </div>
                        </p>                       
                        { 
                            show_payment && show_payment.id === order.id ?
                            
                            drawPayment()
                            :
                            <div><a href='#' onClick={(e) => showPayment(e, order.id)}>View payment</a></div>
                        }
                    </div>                           
        })
        }
        </>
    );
} 