// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.main-block-title{
        
    text-align: center;        
    border-radius: 3px;       
}
`, "",{"version":3,"sources":["webpack://./src/elements/main-block/style.css"],"names":[],"mappings":"AAAA;;IAEI,kBAAkB;IAClB,kBAAkB;AACtB","sourcesContent":[".main-block-title{\n        \n    text-align: center;        \n    border-radius: 3px;       \n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
