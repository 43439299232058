import './style.css';

export default (props) => {
   
    return (                 
        <div>            
            <rssapp-ticker id="ti36LC2ILFMXrrrL"></rssapp-ticker>
            <rssapp-magazine id="ti36LC2ILFMXrrrL"></rssapp-magazine>
            <iframe width="100%" height="1600" src="https://rss.app/embed/v1/wall/tL2oDMKZLXvRFcYf" frameborder="0"></iframe>
        </div>                       
    );
} 