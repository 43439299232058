// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#stats-main{
    
    margin-top:30px
}

#stats-main ul li{
    
    margin:20px 0px;
}

.stats-value{
    
    font-size: 1.3em;
    color: var(--color-a);
    font-weight: italic;
    border-bottom: 1px solid #ffd4e3;
    padding-bottom: 2px;
    padding-right: 30px;

    margin-bottom: 10px;
}

.stats-title{
    
    font-size:1.1em;
    font-family:var(--font-b);
    color:var(--color-b);     
    text-transform: uppercase;
}`, "",{"version":3,"sources":["webpack://./src/components/stats/style.css"],"names":[],"mappings":"AAAA;;IAEI;AACJ;;AAEA;;IAEI,eAAe;AACnB;;AAEA;;IAEI,gBAAgB;IAChB,qBAAqB;IACrB,mBAAmB;IACnB,gCAAgC;IAChC,mBAAmB;IACnB,mBAAmB;;IAEnB,mBAAmB;AACvB;;AAEA;;IAEI,eAAe;IACf,yBAAyB;IACzB,oBAAoB;IACpB,yBAAyB;AAC7B","sourcesContent":["#stats-main{\n    \n    margin-top:30px\n}\n\n#stats-main ul li{\n    \n    margin:20px 0px;\n}\n\n.stats-value{\n    \n    font-size: 1.3em;\n    color: var(--color-a);\n    font-weight: italic;\n    border-bottom: 1px solid #ffd4e3;\n    padding-bottom: 2px;\n    padding-right: 30px;\n\n    margin-bottom: 10px;\n}\n\n.stats-title{\n    \n    font-size:1.1em;\n    font-family:var(--font-b);\n    color:var(--color-b);     \n    text-transform: uppercase;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
