import './style.css';
import React, { useState, useEffect,useRef }    from 'react';
import { ReactComponent as AlgorandSymbol}      from '../../icons/algorand-logo.svg';
import Api                                      from "../../includes/api";
import Contracts                                from "../../includes/contracts";
import AlgoAmount                               from "../../elements/algo-amount/algo-amount";
import Slider                                   from "../../elements/slider/slider";
import algosdk                                  from "algosdk";
 
export default (props) => {
    
    const [claim_amount, setClaimAmount]    = useState(0);
    const [user_balance, setUserBalance]    = useState(0);
  
    const loadUserBalance = async () => {
        
        const user  = await Api.get("user");
        let balance = 0;
        
        if(user.content && user.content?.address){
            
            const address   = user.content.address;            
            balance         = await Contracts.getUserBalance(address);
            
            setUserBalance(balance);
        }                
    };   
    
    useEffect(() => {    
               
        loadUserBalance();
       
    }, []);


    return (
        <>

        <div class="white-block" id="claim-rewards">
            <div class="title">Claim rewards</div>
            
            <table class="table-structure">
            <tr>
                <td class="frozen">Frozen: </td>
                <td class="td-amount frozen"><AlgoAmount value={props.frozen_rewards} icon-width="1em" /></td>
            </tr>
            <tr>
                <td>Claimable:</td>
                <td class="td-amount"><AlgoAmount value={props.claimable_rewards} icon-width="1em" /></td>
            </tr>
            <tr>
                <td>You will receive:</td>
                <td class="td-amount"><AlgoAmount value={claim_amount} icon-width="1em" /></td>
                </tr>           
            </table>
            
            <Slider 
                onChange = {(value) => setClaimAmount(value)} 
                max      = {props.claimable_rewards} 
            />
            <div class="flex" id="buttons-panel">
                <button class="small-btn" onClick={(e) => props.onClaim(claim_amount)}>Proceed</button>
                <button class="small-btn" onClick={props.onClose}>Cancel</button>
            </div>
        </div>    
                    
       
        </>
    );
}

