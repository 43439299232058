// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#top-bar{
      
    display:flex;   
    height:66px;
    background:var(--main-green);
    align-items: center;
    padding:0px 20px;
    flex-wrap: wrap;
    
    #logo{
        
        font-size: 2em;
        font-weight: bold;
    }
    
    #logo-text-1{
        
        color:var(--main-yellow);
    }
    
    #logo-text-2{
        
        color:white;
    }
    
    #socials{
        
        margin-left:20px;
       /*margin-top:3px;*/
    }
    
    #algo-pricing{
    
        display: flex;
        gap: 5px;
        color: white;
        align-items: baseline;
        position: absolute;
        right: 0;
        top: 23px;
        padding-right:10px;
        
        img{

            width:15px;
        }
        #price{


        }
    }

    nav{
        
        margin-left:auto;
        margin-right:150px;
    }
    
    .flex-break{

        display:none;
    }
}`, "",{"version":3,"sources":["webpack://./src/components/top-bar/style.css"],"names":[],"mappings":"AAAA;;IAEI,YAAY;IACZ,WAAW;IACX,4BAA4B;IAC5B,mBAAmB;IACnB,gBAAgB;IAChB,eAAe;;IAEf;;QAEI,cAAc;QACd,iBAAiB;IACrB;;IAEA;;QAEI,wBAAwB;IAC5B;;IAEA;;QAEI,WAAW;IACf;;IAEA;;QAEI,gBAAgB;OACjB,kBAAkB;IACrB;;IAEA;;QAEI,aAAa;QACb,QAAQ;QACR,YAAY;QACZ,qBAAqB;QACrB,kBAAkB;QAClB,QAAQ;QACR,SAAS;QACT,kBAAkB;;QAElB;;YAEI,UAAU;QACd;QACA;;;QAGA;IACJ;;IAEA;;QAEI,gBAAgB;QAChB,kBAAkB;IACtB;;IAEA;;QAEI,YAAY;IAChB;AACJ","sourcesContent":["#top-bar{\r\n      \r\n    display:flex;   \r\n    height:66px;\r\n    background:var(--main-green);\r\n    align-items: center;\r\n    padding:0px 20px;\r\n    flex-wrap: wrap;\r\n    \r\n    #logo{\r\n        \r\n        font-size: 2em;\r\n        font-weight: bold;\r\n    }\r\n    \r\n    #logo-text-1{\r\n        \r\n        color:var(--main-yellow);\r\n    }\r\n    \r\n    #logo-text-2{\r\n        \r\n        color:white;\r\n    }\r\n    \r\n    #socials{\r\n        \r\n        margin-left:20px;\r\n       /*margin-top:3px;*/\r\n    }\r\n    \r\n    #algo-pricing{\r\n    \r\n        display: flex;\r\n        gap: 5px;\r\n        color: white;\r\n        align-items: baseline;\r\n        position: absolute;\r\n        right: 0;\r\n        top: 23px;\r\n        padding-right:10px;\r\n        \r\n        img{\r\n\r\n            width:15px;\r\n        }\r\n        #price{\r\n\r\n\r\n        }\r\n    }\r\n\r\n    nav{\r\n        \r\n        margin-left:auto;\r\n        margin-right:150px;\r\n    }\r\n    \r\n    .flex-break{\r\n\r\n        display:none;\r\n    }\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
