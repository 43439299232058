import './style.css';
import Toggle       from '../../elements/toggle/toggle';
import ButtonFat    from '../../elements/button-fat/button-fat';
import Api          from '../../includes/api';
import MemeSubmit   from './meme-submit';
import Loader       from '../../elements/loader/loader';
import Masonry      from 'react-responsive-masonry'

import React, { useState, useEffect, useRef }    from 'react';

export default (props) => {
     
    const [chains,              setChains]          = useState([]); // List of registered chains
    const [selected_chains,     setSelectedChains]  = useState([1]); // Toggled-on chains
    const [is_logged_in,        setIsLoggedIn]      = useState(false);  
    const [show_memes_loader,   setShowMemesLoader] = useState(false);
    const [current_page,        setCurrentPage]     = useState(0);
    const [memes,               setMemes]           = useState([]); // list of memes to display
    const [modal_picture,       setModalPicture]    = useState(null); // show a picture as modal
    const [fixed_left_panel,    setFixedLeftPanel]  = useState(false);
    
    useEffect( () => {
        
        isLoggedIn();
        getChainsList();
        listMemes();
        
    }, []);
    
    useEffect( () => {
        
       listMemes();
        
    }, [selected_chains]);
    
    useEffect(() => {
        
        document.addEventListener('scroll', handleScroll);

        return () => {
            
          document.removeEventListener('scroll', handleScroll);
        };        
    });
    
    const handleScroll = (e) => {
        
        if(window.scrollY >= 125){
            
            setFixedLeftPanel(true);
        }
        else{
            
            setFixedLeftPanel(false);
        }
    };
    
    const isLoggedIn = async () => {
        
        const is_logged_in = await Api.get("user");
        
        if( !is_logged_in.error && is_logged_in.content){
            
            setIsLoggedIn(true);
        }
        else{
         
            setIsLoggedIn(false);
        }
    };  
    
    const getChainsList = async () => {
        
        const chains                = await Api.get("memes/chains");
        const chains_with_content   = await Api.get("memes/chains", {with_content:true});

        setChains(chains.content);
        setSelectedChains(chains_with_content.content);
    };
   
    const drawToggles = (chains, selected_chains) => {
        
        const res = [];
        
        chains.map(chain => {

            res.push(<label class={isToggleOn(chain.id) ? "" : "off"}>
                        <Toggle 
                            onChange    = {onChangeToggle} 
                            state       = {isToggleOn(chain.id) ? "on" : "off"} 
                            id          = {chain.id} />{chain.chain}
                    </label>)
        });
        
        return res;
    };
    
    const onChangeToggle = (value, chain) => {        
        
        if(value === "on"){
                        
            selected_chains.push(chain);
        
            setSelectedChains([...selected_chains]);
        }
        else{
            
            const new_entries = selected_chains.filter(entry => entry !== chain);
            
            setSelectedChains(new_entries);
        }                
    };
       
    const isToggleOn = (chain) => {
        
        return selected_chains.includes(chain);
    };
    
    const listMemes = async () => {
        
        if(selected_chains.length > 0){
            
            setShowMemesLoader(true);

            const list = await Api.get("memes", {page:0, chains:selected_chains});

            setMemes(list.content);
            setCurrentPage(0);

            setShowMemesLoader(false);
        }
        else{
            
            setMemes([]);
        }
    };
    
    const onClickLoadMore = async () => {

        const next = current_page + 1;
        const list = await Api.get("memes", {page:next, chains:selected_chains});
       
        for(const meme of list.content){
            
            memes.push(meme);
        }
        setMemes([...memes]);
        setCurrentPage(next);
    };
    
    const showModalPicture = (e) => {
        
        setModalPicture(e.target.currentSrc);
    };
    
    return (                 
        <div id="memes-container">
            
            <div id="left-side">
                {is_logged_in ? <p><ButtonFat value="View pending memes" onClick={onClickLoadMore} /></p> : ""}
                <div class={fixed_left_panel ? "left-side-floating" : ""}>
                    <div class="white-container" id="submit-meme-button-container">                        
                        <MemeSubmit chains={chains}/>
                    </div>
                    <div class="white-container" id="toggles-panel">
                    { drawToggles(chains, selected_chains) }                       
                    </div>
                </div>
            </div>
            
            <div id="content">             
                {
                    modal_picture ? <div id="modal-picture-mask" onClick={(e) => setModalPicture(null)}>
                                        <div id="picture-container"><img id="picture" src={modal_picture} /></div>
                                    </div> : ""
                }
                
                {
                    show_memes_loader ? 
                        <Loader /> 
                    :
                        <>
                        <Masonry columnsCount={4} gutter="20px">
                        {
                            memes.map((meme, index) => {

                                return  <div key={index} >
                                            <div  class="white-container">
                                            <img class="thumbnail" src={"data:image/png;base64,"+meme.base64} onClick={showModalPicture}/>
                                            </div>
                                        </div>
                            })
                        }
                        </Masonry>
                        <p>
                        { memes.length > 0 ? <ButtonFat value="Load more" onClick={onClickLoadMore} /> : "" }
                        </p>
                        </>
                }                
                
           </div>
        </div>
    );
} 