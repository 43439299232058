import './style.css';
import Button   from '../../elements/button-fat/button-fat';
import Password from '../../elements/password/password';
import Api      from '../../includes/api';

import React, { useState, useEffect,useRef }    from 'react';

export default (props) => {
   
    const [email_address,       setEmailAddress]        = useState(null);
    const [password,            setPassword]            = useState({password:null, strength:null, description:null});   
    const [show_clear_password, setShowClearPassword]   = useState(false);
    const [errors,              setErrors]              = useState({email:null, password:null});
    
    useEffect( () => {

        redirectIfIsLoggedIn();              
    });
    
    const redirectIfIsLoggedIn = async () => {
        
        const is_logged_in = await Api.get("user");
        
        if( !is_logged_in.error && is_logged_in.content){
            
            window.location.pathname = "/profile";
        }        
    };
    
    const onClickCreate = async () => {

        setErrors({email:null, password:null});
        
        const auth = await Api.post("user/create", {email_address:email_address, password:password.password});
     
        if( auth.error){
            
            if(auth.content === "invalid_email_address"){

                setErrors({...errors, email:"Invalid email address"});
            }
            else if(auth.content === "email_already_registered"){
                
                setErrors({...errors, email:"Email address already registered"});
            }
            else if(auth.content === "password_too_weak"){
                
                setErrors({...errors, password:"Enter a stronger password"});
            }
            //
            
            //window.navigation.reload();
        }
        else{
            
            localStorage.setItem("auth", auth.content);
            
            window.location.pathname = "/profile";
        }
    };       
    
    const onChangePassword = (password, strength, description) => {
        
        setPassword({password:password, strength:strength, description:description});      
    };
    
    return (                 
        <div id="create-account-content">
            <div class="white-container">
            <label>
                Email address  
                {errors.email ? <div class="error">{errors.email}</div> : ""}
                <input class="large-input" type="text" onChange={e => setEmailAddress(e.target.value)} />
            </label>  
            <label>
                Select a strong password   
                <div>{errors.password ? <div class="error">{errors.password}</div> : ""}</div>
                <Password password={password.password} onChange={onChangePassword} />                
                <div id="create-password-strength" class={password.strength}>{password.description}</div>
            </label> 
            <Button value='Create account' onClick={onClickCreate} />
            
            </div>   
        </div>                    
    );
} 