import './style.css';
import { ReactComponent as SignOut }    from '../../icons/sign-out.svg';
import { ReactComponent as Discord }    from '../../icons/discord.svg';
import { ReactComponent as Twitter }    from '../../icons/twitter.svg';

export default (props) => {
   

    return (                 
        <div id="top-bar">            
            <div class="block"><a id="discord" href="https://discord.gg/tjPRVMJyRF" target="_blank"><Discord class="icon" /></a></div>
            <div class="block"><a href="https://twitter.com/algoproposers" target="_blank"><Twitter class="icon" /></a></div>
            <div class="block"><SignOut class="icon" /></div>
        </div>                       
    );
} 