import axios    from "axios";
import algosdk  from "algosdk";

const host_indexer = "https://testnet-idx.algonode.cloud/";
const host_algod = "https://testnet-api.algonode.cloud/";
const algod      = new algosdk.Algodv2("", host_algod, "")

const request = async (host,  url = "", params = null) => {
           
    let res         = null;
    host            = host === "indexer" ? host_indexer : host_algod;
    let connection  = {
        
        method:     "get",
        url:        host+url,
        data:       params,          
    };
   
    if(connection.method === "get"){
        
        connection.params = params;
    }  
    
    try{
       
        const exec  = await axios(connection);         
        res         = exec?.data ?? null;        
    }        
    catch(e){
                
        res  = e?.response?.data ?? null;        
    }  
    
    return res;
};

const nodely = {
 
    getFromIndexer: async (endpoint = "", params = {}) => {

        return request("indexer", endpoint, params);
    },
    
    getFromAlgod: async (endpoint = "", params = {}) => {

        return request("algod", endpoint, params);
    },
    
    getAlgod: () => {
        
        return algod;
    },
    
    /*post: async (endpoint = "", params = {}) => {

        return request(getHeaders("post"), endpoint, params);
    }*/
};

export default nodely;