// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#schedule-event-button{
    
    margin-bottom:20px;
    text-align:center;
}

#schedule-event{
    
    margin:auto;
    max-width:800px;
    
    input[type=text], textarea, input[type=datetime-local]{
    
        display:block
    }
    
    input, textarea{
        
        margin-top:15px;
        margin-bottom:50px;
    }
    
    .large-input, textarea{
        
        max-width:500px;
        width:500px;
    }
    
    textarea{
        
        height:100px;
    }
    
    .error{
        
        color: red;
        margin-top: 10px;
    }
    
    #success-publish-message{
        
        text-align:center;
    }
}


`, "",{"version":3,"sources":["webpack://./src/components/events/style.css"],"names":[],"mappings":"AAAA;;IAEI,kBAAkB;IAClB,iBAAiB;AACrB;;AAEA;;IAEI,WAAW;IACX,eAAe;;IAEf;;QAEI;IACJ;;IAEA;;QAEI,eAAe;QACf,kBAAkB;IACtB;;IAEA;;QAEI,eAAe;QACf,WAAW;IACf;;IAEA;;QAEI,YAAY;IAChB;;IAEA;;QAEI,UAAU;QACV,gBAAgB;IACpB;;IAEA;;QAEI,iBAAiB;IACrB;AACJ","sourcesContent":["#schedule-event-button{\r\n    \r\n    margin-bottom:20px;\r\n    text-align:center;\r\n}\r\n\r\n#schedule-event{\r\n    \r\n    margin:auto;\r\n    max-width:800px;\r\n    \r\n    input[type=text], textarea, input[type=datetime-local]{\r\n    \r\n        display:block\r\n    }\r\n    \r\n    input, textarea{\r\n        \r\n        margin-top:15px;\r\n        margin-bottom:50px;\r\n    }\r\n    \r\n    .large-input, textarea{\r\n        \r\n        max-width:500px;\r\n        width:500px;\r\n    }\r\n    \r\n    textarea{\r\n        \r\n        height:100px;\r\n    }\r\n    \r\n    .error{\r\n        \r\n        color: red;\r\n        margin-top: 10px;\r\n    }\r\n    \r\n    #success-publish-message{\r\n        \r\n        text-align:center;\r\n    }\r\n}\r\n\r\n\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
