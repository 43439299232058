import './style.css';
import Block from './block';
export default (props) => {
  
    return (

        <section id="stats-main">
            <ul>
                <li><Block title="proposers" value="2" /></li>
                <li><Block title="Voting stake" value="37" /></li>
                <li><Block title="proposed blocks" value="0" /></li>
                <li><Block title="rewards" value="soon" /></li>
            </ul>
        </section> 
    );
}

