import './style.css';


export default (props) => {
            
    return (
          
        <>
       
        <div>               
            <div class={"main-block-title "+props.titleClass}>{props.title}</div>
            <div class={props.contentClass}>{props.children}</div>
        </div>
                          
       </>
    );
}