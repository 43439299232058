import TopBar               from "./components/top-bar/top-bar";
import Footer               from "./components/footer/footer";
import Home                 from "./components/home/home";
import Events               from "./components/events/events";
import ScheduleEvent        from "./components/events/schedule";
import Poker                from "./components/poker/poker";
import Store                from "./components/store/store";
import StoreAdd             from "./components/store/add";
import StoreOrders          from "./components/store/orders";
import StorePayment         from "./components/store/payment";
import StorePaymentSuccess  from "./components/store/payment-success";
import Memes                from "./components/memes/memes";
import MemesPending         from "./components/memes/pending";
import Blog                 from "./components/blog/blog";
import BlogArticle          from "./components/blog/blog-article";
import Services             from "./components/services/services";
import Profile              from "./components/profile/profile";
import ProfileEvents        from "./components/profile/events";
import ProfileOrders        from "./components/profile/orders";
import CreateAccount        from "./components/profile/create-account";
import ForgottenPassword    from "./components/profile/forgotten-password";
import { WalletProvider, useInitializeProviders, PROVIDER_ID } from '@txnlab/use-wallet'
import { DeflyWalletConnect } from '@blockshake/defly-connect'
import { PeraWalletConnect } from '@perawallet/connect'
import { DaffiWalletConnect } from '@daffiwallet/connect'
import LuteConnect from 'lute-connect'
import  {BrowserRouter, Routes, Route} from "react-router-dom";
import  './App.css';

function App() {
     
    const res = [];        
    const providers = useInitializeProviders({
        providers: [
            
            { id: PROVIDER_ID.DEFLY, clientStatic: DeflyWalletConnect },
            { id: PROVIDER_ID.PERA, clientStatic: PeraWalletConnect },
            { id: PROVIDER_ID.DAFFI, clientStatic: DaffiWalletConnect },
            { id: PROVIDER_ID.EXODUS },
            {
              id: PROVIDER_ID.LUTE,
              clientStatic: LuteConnect,
              clientOptions: { siteName: 'AlgorandCulture' }
            }
        ],
        nodeConfig: {
            network: 'testnet',
            nodeServer: 'https://testnet-api.algonode.cloud',
            nodeToken: '',
            nodePort: '443'
        }
    });
    res.push(
        <> 
        
        <header>           
            <TopBar />
        </header>
        <main>           
            <BrowserRouter>    
               <Routes>
                   <Route path="/"                      element={<Home />} />                               
                   <Route path="/events"                element={<Events />} /> 
                   <Route path="/events/schedule"       element={<ScheduleEvent />} />
                   <Route path="/poker"                 element={<Poker />} /> 
                   <Route path="/store"                 element={<Store />} /> 
                   <Route path="/store/add"             element={<StoreAdd />} />
                   <Route path="/store/payment"         element={<StorePayment />} />
                   <Route path="/store/payment/success" element={<StorePaymentSuccess />} />
                   <Route path="/store/orders"          element={<StoreOrders />} />
                   <Route path="/memes"                 element={<Memes />} />
                   <Route path="/memes/pending"         element={<MemesPending />} />
                   <Route path="/blog"                  element={<Blog />} /> 
                   <Route path="/blog/:title"           element={<BlogArticle />} />                   
                   <Route path="/services"              element={<Services />} /> 
                   <Route path="/profile"               element={<Profile />} /> 
                   <Route path="/profile/events"        element={<ProfileEvents />} /> 
                   <Route path="/profile/orders"        element={<ProfileOrders />} /> 
                   <Route path="/create-account"        element={<CreateAccount />} /> 
                   <Route path="/forgotten-password"    element={<ForgottenPassword />} /> 
               </Routes>         
            </BrowserRouter>           
        </main>  
        <footer> 
            <Footer />
        </footer>
       
        </>
    );
     
    return <WalletProvider value={providers}>{res}</WalletProvider>
}

export default App;
